// 智能终端数据

export const terminalList = [
  {
    label: '智能识读终端',
    children: [
      {
        label: 'T10-F',
        desc: '扫码、刷卡，统统在行',
        imgsrc: require('@/assets/img/terminal/terminalImages/T10-F.png'),
        advantageList: [
          {
            img: require('@/assets/img/terminal/syty.png'),
            title: '使用体验',
            tip: '配置蜂鸣器、指示灯，提升用户的使用体验',
          },
          {
            img: require('@/assets/img/terminal/sjff.png'),
            title: '数据丰富',
            tip: '丰富的API 接口，可快速加载支付和行业应用',
          },
          {
            img: require('@/assets/img/terminal/gnqm.png'),
            title: '功能全面',
            tip: '功能模块全面集成，可读二代证信息和二维码扫码，支持多种丰富的业务功能',
          },
          {
            img: require('@/assets/img/terminal/zcff.png'),
            title: '支持丰富',
            tip: '支持Window XP、7、8、10系统，及Linux系统',
          },
          {
            img: require('@/assets/img/terminal/zgzs.png'),
            title: '做工扎实',
            tip: '触觉手感好，采用人体工程学设计，尽显高雅',
          },
          {
            img: require('@/assets/img/terminal/yxzc.png'),
            title: '优秀支持',
            tip: '支持非接触卡、接触式读卡写，二代身份证读取',
          },
        ],
        prodParams: [
          {
            label: '支持系统',
            value: 'Windows、Linux、Android',
          },
          {
            label: '接触式卡',
            value: '支持符合ISO 7816标准的接触式卡，采用下降式卡座',
          },
          {
            label: '非接触式',
            value: '支持读写ISO14443 Type A/B标准的非接触卡',
          },
          {
            label: '读磁条卡',
            value: '支持读取1、2、3轨的磁条卡',
          },
          {
            label: 'PSAM卡',
            value: '同时可附加4个符合GSM 11.11的SAM卡座，可支持Sim卡尺寸',
          },
          {
            label: '状态显示',
            value: '4个LED指示灯，指示电源、通讯、读卡、交易等状态',
          },
          {
            label: '通讯方式',
            value: 'RS232通讯',
          },
          {
            label: '电源',
            value: '外置9V 1A电源适配器',
          },
          {
            label: '按键',
            value: '可外接密码键盘',
          },
          {
            label: '所遵循的标准',
            value: 'ISO7816、IS014443、GSM11.11、FCC、ROHS、CE、CCC',
          },
          {
            label: '工作环境',
            value: '温度：-10℃～60℃；湿度：95%',
          },
        ],
      },
      {
        label: 'F11P双屏机',
        desc: '双屏智能行业终端',
        imgsrc: require('@/assets/img/terminal/terminalImages/F11P_spj.png'),
        prodParams: [
          {
            label: '操作系统',
            value: 'Android 11',
          },
          {
            label: '中央处理器',
            value: '瑞芯微RK3568 四核64位Cortex-A55，主频最高2.0GHz',
          },
          {
            label: '存储',
            value: '4GB LPDDR4+ 32GB eMMC',
          },
          {
            label: '主屏',
            value: '15.6寸，1920×1080，支持多点电容触摸',
          },
          {
            label: '副屏',
            value: '15.6寸，1920×1080，支持多点电容触摸（可选10.1寸）',
          },
          {
            label: '无线通信',
            value: '支持WIFI、蓝牙、4G全网通',
          },
          {
            label: '扬声器',
            value: '支持，2W',
          },
          {
            label: '物理接口',
            value: 'DC电源接口、USB A 2.0接口、RJ11接口、LAN接口、TF卡接口',
          },
          {
            label: '非接触卡读写器',
            value: '符合ISO/IEC 14443标准，支持TypeA/B， Mifare卡， 协议ISO14443 /1/2/3/4 T=CL协议',
          },
          {
            label: '接触式卡读写器',
            value: '符合ISO/IEC 7816标准 支持异步卡如T=0、T=1的CPU卡，同步卡如常用的存储卡AT24系列、4442、4428等卡型， 支持1.8v/3v/5v卡',
          },
          {
            label: '磁条卡阅读器',
            value: '支持磁条卡信息阅读，可选设计',
          },
          {
            label: '条码阅读器',
            value: '支持符合国内外通用的一维码与二维码',
          },
          {
            label: 'SAM卡座',
            value: '4个，符合ISO/IEC 7816标准',
          },
          {
            label: '蜂鸣器',
            value: '支持，无源',
          },
          {
            label: 'LED指示灯',
            value: '支持4个，可控',
          },
          {
            label: '热敏打印机',
            value: '支持58mm打印机，纸卷直径最大50MM',
          },
          {
            label: '电源适配器',
            value: '输入：AC100-240V/1.5A 输出：DC24V/2.5A',
          },
          {
            label: '上电开机',
            value: '支持上电自动开机',
          },
          {
            label: '电源键',
            value: '支持关机、开机、重启',
          },
          {
            label: '工作环境',
            value: '温度：-10℃～50℃，相对湿度：5℅～93℅（非冷凝）',
          },
          {
            label: '存储环境',
            value: '温度：-20℃～60℃，相对湿度：5℅～93℅（非冷凝）',
          },
        ],
      },
      {
        label: 'T60',
        desc: '支持一维码、二维码功能',
        imgsrc: require('@/assets/img/terminal/terminalImages/T60.png'),
        prodParams: [
          {
            label: '操作系统',
            value: 'RTOS实时操作系统',
          },
          {
            label: '中央处理器',
            value: '32位安全芯片处理器',
          },
          {
            label: '显示屏',
            value: '3.2寸 TFT液晶屏，320*240',
          },
          {
            label: '指示灯',
            value: '4个，可控，从左到右：蓝黄绿红',
          },
          {
            label: '补光灯',
            value: '4个，可控，扫码补光',
          },
          {
            label: '蜂鸣器',
            value: '无源，支持',
          },
          {
            label: '语音',
            value: '支持，可自定义',
          },
          {
            label: '扬声器',
            value: '支持，单设计，具体结构定义',
          },
          {
            label: '物理接口',
            value: 'RJ45母座转RJ45母座（USB HID或以太网）、RJ45母座转DC母座、RJ11母座',
          },
          {
            label: '非接触卡读写器',
            value: '符合ISO/IEC 14443标准，支持TypeA/B， Mifare卡',
          },
          {
            label: '卡座',
            value: 'PSAM卡座4个，符合ISO/IEC 7816标准；SIM卡座1个',
          },
          {
            label: '扫码模块',
            value: '支持常见的一维码与二维码扫码',
          },
          {
            label: '全网通模块',
            value: 'CAT1通讯模组',
          },
          {
            label: 'WIFI模组',
            value: '支持，此模组与4G不能同时使用',
          },
          {
            label: '实时时钟',
            value: '支持',
          },
          {
            label: '电源',
            value: '外置电源适配器5V±5%，2A',
          },
          {
            label: '工作环境',
            value: '温度：-10℃～50℃；相对湿度：5℅～95℅（非冷凝）',
          },
          {
            label: '存储环境',
            value: '温度：-20℃～60℃；相对湿度：5℅～95℅（非冷凝）',
          },
        ],
      },
      {
        label: 'T10-P',
        desc: '小巧机身、应用方便',
        imgsrc: require('@/assets/img/terminal/terminalImages/T10-P.png'),
        prodParams: [
          {
            label: '中央处理器',
            value: '32位CPU',
          },
          {
            label: '指示灯',
            value: '从左到右：蓝黄绿红（银联标准）',
          },
          {
            label: '蜂鸣器',
            value: '支持，无源设计',
          },
          {
            label: '物理接口及通讯方式',
            value: '1个RJ45插座、1个RJ11插座、2个USB A型母座、1个DC插座',
          },
          {
            label: 'IC卡读卡器',
            value: '1个，符合ISO/IEC 7816标准',
          },
          {
            label: '非接触卡读写器',
            value: '符合ISO/IEC 14443标准，支持TypeA/B， Mifare卡',
          },
          {
            label: '磁条卡读卡器',
            value: '支持磁条卡/存折的1/2/3轨道的信息读取，支持双向单面刷卡',
          },
          {
            label: '二代证',
            value: '支持第二代居民身份证信息的读取，支持德卡标准协议与公安部直连协议。',
          },
          {
            label: 'SAM卡座',
            value: '4个，符合ISO/IEC 7816标准，支持5V/3V/1.8V卡（直接3.3V）',
          },
          {
            label: 'SIM卡座',
            value: '1个',
          },
          {
            label: '安全芯片',
            value: 'ESAM，1个,模块设计12*12mmSPI 和 7816接口都预留',
          },
          {
            label: '打印机',
            value: '支持热敏打印功能，支持58*50mm的纸卷',
          },
          {
            label: '扫码模块',
            value: '支持常见的一二维码扫码',
          },
          {
            label: '蓝牙模块',
            value: '支持BT4.0+BR/EDR+BLE',
          },
          {
            label: 'WIFI模块',
            value: '支持WIFI',
          },
          {
            label: '全网通模块',
            value: 'TD_LTE: B38/39/40/41 FD_LTE: B1/3 TD_SCDMA:B34/39 WCDMA: B1/B5 EVDO: BC0 CDMA: BC0 GSM: B5/3/8',
          },
          {
            label: '电源',
            value: '外置12V 3A电源适配器',
          },
          {
            label: '驱动程序可支持操作系统',
            value: 'WIN XP/7/8/10 ,LINUX,安卓',
          },
          {
            label: '工作环境',
            value: '-10°—+50° 湿度5%-93%',
          },
          {
            label: '存储环境',
            value: '-20°—+60° 湿度3%-95%',
          },
          {
            label: '配线',
            value: '二选一：1、1.5M USB接口线缆 2、1.5M DB9接口线缆',
          },
        ],
      },
      {
        label: 'M100',
        desc: '读卡、图像采集“一拍即合”',
        imgsrc: require('@/assets/img/terminal/terminalImages/M100.png'),
        advantageList: [
          {
            img: require('@/assets/img/terminal/jcjy.png'),
            title: '即插即用',
            tip: '采用USB标准接口与上位机相连，即插即用',
          },
          {
            img: require('@/assets/img/terminal/sjff.png'),
            title: '数据丰富',
            tip: '丰富的API接口，可快速加载支付和行业应用',
          },
          {
            img: require('@/assets/img/terminal/qjqx.png'),
            title: '取景清晰',
            tip: '配置高清摄像头',
          },
          {
            img: require('@/assets/img/terminal/zgzs.png'),
            title: '做工扎实',
            tip: '触觉手感好，采用人体工程学设计，尽显高雅',
          },
          {
            img: require('@/assets/img/terminal/yyzw.png'),
            title: '易用指纹',
            tip: '可配置指纹识别模块',
          },
          {
            img: require('@/assets/img/terminal/yxzc.png'),
            title: '优秀支持',
            tip: '支持非接触卡、接触式读卡写，二代身份证读取',
          },
        ],
        prodParams: [
          {
            label: '支持系统',
            value: 'Windows、Linux、Android',
          },
          {
            label: '处理器',
            value: '32位处理器',
          },
          {
            label: '蜂鸣器',
            value: '支持',
          },
          {
            label: '通讯接口',
            value: 'USB',
          },
          {
            label: '接触式卡',
            value: '支持符合ISO/IEC 7816标准的IC卡',
          },
          {
            label: '非接触卡',
            value: '支持符合ISO14443 TypeA/B标准感应IC卡、Mifare卡',
          },
          {
            label: 'SAM卡座',
            value: '4个',
          },
          {
            label: '读二代证',
            value: '支持读取中华人民共和国第二代身份证',
          },
          {
            label: '摄像头',
            value: '2个',
          },
          {
            label: '指纹识别',
            value: '可选',
          },
          {
            label: '电源',
            value: '电源适配器或USB口供电，DC5V±5%',
          },
          {
            label: '散热',
            value: '带有两个10cm以上散热孔',
          },
          {
            label: '工作环境',
            value: '温度:-10°C~50°C;相对湿度:5%~93%(非冷凝)',
          },
          {
            label: '存储环境',
            value: '温度:-20°C~60°C;相对湿度:5%~95%(非冷凝)',
          },
          {
            label: '符合标准',
            value: 'qPBOC、PBOC、EMV、人社部、住建部、卫健委',
          },
        ],
      },
    ],
  },
  {
    label: '医保业务终端',
    children: [
      {
        label: 'F11/F11C',
        desc: '支付  不论方式',
        imgsrc: require('@/assets/img/terminal/terminalImages/F11Q.png'),
        prodParams: [
          {
            label: '操作系统',
            value: 'Android 8.1',
          },
          {
            label: '中央处理器',
            value: '8核处理器，1.8GHz',
          },
          {
            label: '存储',
            value: '4GB RAM+64GB ROM',
          },
          {
            label: '显示屏',
            value: '主屏8寸TFT全视角液晶屏，分辨率800x1280 全贴合电容多点触摸屏',
          },
          {
            label: '摄像头',
            value: '支持3D结构光摄像头',
          },
          {
            label: '扫码模块',
            value: '支持常见的一维码和二维码识读',
          },
          {
            label: '指示灯',
            value: '4个，颜色：蓝黄绿红各一个',
          },
          {
            label: '按键',
            value: '电源键， 音量加/减键',
          },
          {
            label: '蜂鸣器',
            value: '支持',
          },
          {
            label: '扬声器',
            value: '支持扬声器语音播报',
          },
          {
            label: '通讯接口',
            value: 'USB接口、RJ11接口、RJ45接口',
          },
          {
            label: '非接触卡读写',
            value: '支持符合ISO14443 Type A/B标准感应IC卡 支持第二代居民身份证信息的读取',
          },
          {
            label: '接触卡读写',
            value: '支持符合ISO7816标准的接触式IC卡',
          },
          {
            label: 'SAM卡座',
            value: '4个，符合 ISO/IEC 7816 标准',
          },
          {
            label: '通讯方式',
            value: '支持4G、以太网（10/100M自适应）、WIFI',
          },
          {
            label: '电源',
            value: '外置DC 12V 3A电源',
          },
          {
            label: '工作环境',
            value: '温度：-10℃～50℃',
          },
          {
            label: '存储环境',
            value: '温度：-20℃～60℃',
          },
        ],
      },
      {
        label: 'P10',
        desc: '为便携移动医保而生',
        imgsrc: require('@/assets/img/terminal/terminalImages/P10.png'),
        prodParams: [
          {
            label: '操作系统',
            value: 'Android 11',
          },
          {
            label: '处理器',
            value: '8核A55 CPU 1.6GHz',
          },
          {
            label: '存储器',
            value: '64GB ROM+2GB RAMDDR4/ 32GB ROM+2GB RAM （可选）',
          },
          {
            label: '显示屏',
            value: `5.99" HD+，1440x720，IPS， 电容多点触控`,
          },
          {
            label: '摄像头',
            value: '前置：3D结构光 后置：5M AF',
          },
          {
            label: 'NFC读卡器',
            value: '支持',
          },
          {
            label: '扫码器',
            value: '二维扫码头',
          },
          {
            label: '喇叭',
            value: '1.2W',
          },
          {
            label: '卡槽',
            value: '1 nano SIM卡+1 Sd卡+1 PSAM卡',
          },
          {
            label: 'GPS',
            value: '内置GPS，支持AGPS',
          },
          {
            label: '通讯方式',
            value: 'LTE/UMTS',
          },
          {
            label: '网络类型',
            value: '4G全网通',
          },
          {
            label: 'WIFI',
            value: '2.4G/5G，支持IEEE 802.11 ac/a/b/g/n',
          },
          {
            label: '蓝牙',
            value: '支持蓝牙4.2，支持BLE',
          },
          {
            label: '接触式读卡器',
            value: '支持ISO/IEC 7816',
          },
          {
            label: '非接触读卡器',
            value: '支持持ISO/IEC 14443 TypeA&B',
          },
          {
            label: '二代证',
            value: '支持第二代居民身份证',
          },
          {
            label: '磁卡',
            value: '支持1/2/3磁道卡 支持双向刷卡 符合ISO7810/7811规范',
          },
          {
            label: '麦克风',
            value: '支持1个',
          },
          {
            label: '接口',
            value: 'USB Type-C 支持耳机功能',
          },
          {
            label: '按键',
            value: '电源键（锁屏键），功能键，音量键',
          },
          {
            label: '提示灯',
            value: '三色灯',
          },
          {
            label: '打印机',
            value: '58mm打印机',
          },
          {
            label: '电池',
            value: '锂电池，7.6V/3500mAh（典型值）',
          },
          {
            label: '充电器',
            value: 'Input:AC 100~240V Output:5V/2A',
          },
          {
            label: '工作环境',
            value: '温度:-10°C~50°C;相对湿度:5%~95%(非冷凝)',
          },
          {
            label: '存储环境',
            value: '温度:-40°C~70°C;相对湿度:5%~95%(非冷凝)',
          },
        ],
      },

      {
        label: 'M300LC-Y3F',
        desc: '医保终端和自助设备一体化',
        imgsrc: require('@/assets/img/terminal/terminalImages/M300LC-Y3F.png'),
        prodParams: [
          {
            label: '操作系统',
            value: 'window 10 64位企业版操作系统',
          },
          {
            label: '中央处理器',
            value: 'I5 第10代CPU（I5-10400）',
          },
          {
            label: '存储',
            value: '8GB DDR4，256G固态硬盘',
          },
          {
            label: '显示屏',
            value: '32英寸液晶显示触摸一体屏+8寸医保刷脸平板',
          },
          {
            label: '触摸屏',
            value: '电容触摸屏，10点触控，钢化玻璃面板',
          },
          {
            label: '支付摄像头',
            value: '微信刷脸支付3D摄像头',
          },
          {
            label: '人脸摄像头',
            value: '单目摄像头，USB接口，200W像素',
          },
          {
            label: '电动读卡器',
            value: '支持接触式IC卡读写， 符合ISO/IEC 7816标准，支持CPU卡，存储卡，逻辑加密卡； 支持非接接触式IC卡读写， 符合ISO/IEC 14443标准，支持TypeA/B，M1卡； 支持磁条卡信息阅读， 符合 ISO7810 和 7811 标准',
          },
          {
            label: '身份证阅读器',
            value: '支持第二代居民身份证（含指纹版）， 支持外国人永久居留身份证， 支持港澳台居民居住证',
          },
          {
            label: '条码阅读器',
            value: '支持符合国内外通用的一维码与二维码',
          },
          {
            label: 'PSAM卡座',
            value: '4个，符合ISO/IEC 7816标准',
          },
          {
            label: '凭条打印机',
            value: '热敏打印，支持纸卷宽度80mm，纸卷外径80mm',
          },
          {
            label: '密码键盘',
            value: '支持标准银联金属密码键盘。 0~9 十个数字键， 小数点、00、取消、更正、确认五个功能键 一个可自定义功能键',
          },
          {
            label: '报告单打印',
            value: '激光打印机，A4黑白打印',
          },
          {
            label: '发卡器',
            value: '（选配）支持三卡或双卡功能，磁卡+接触+非接卡或接触+非接卡读写',
          },
          {
            label: '纸币器',
            value: '（选配）可接受1元、5元、10元、20元、50元、100元人民币， 可接收纸币4个方向放入； 支持针孔监控摄像头',
          },
          {
            label: 'WIFI',
            value: '支持',
          },
          {
            label: '以太网',
            value: '支持',
          },
          {
            label: '扬声器',
            value: '支持双扬声器，8Ω5W',
          },
          {
            label: '人体感应',
            value: '支持红外人体感应，感应距离：10cm-100cm',
          },
          {
            label: 'USB接口',
            value: '外置两个HOST USB接口',
          },
          {
            label: 'UPS电源',
            value: '支持UPS电源，标称容量500VA',
          },
          {
            label: '电源设计',
            value: '支持AC220V电源输入，支持F10AL 250V保险丝',
          },
          {
            label: '电源开关',
            value: '支持电源开关按钮',
          },
          {
            label: '自动开关机',
            value: '支持自动开关机',
          },
          {
            label: '电源开关',
            value: '支持电源开关切断整机电源',
          },
        ],
      },
    ],
  },
  {
    label: '自助一体化终端',
    children: [
      {
        label: 'M300L',
        desc: '永不下班的“服务台”',
        imgsrc: require('@/assets/img/terminal/terminalImages/M300L.png'),
        advantageList: [
          {
            img: require('@/assets/img/terminal/mzzzfw.png'),
            title: '门诊自助服务',
            tip: '签约发卡、医生签到、自助挂号、预约取号、门诊缴费、扫码绑卡、自助查询、排队叫号',
          },
          {
            img: require('@/assets/img/terminal/zyzzfw.png'),
            title: '住院自助服务',
            tip: '押金充值、自助补缴、自助查询、护士巡房签到',
          },
          {
            img: require('@/assets/img/terminal/nbgl.png'),
            title: '内部管理',
            tip: '考勤、会议签到、食堂缴费',
          },
          {
            img: require('@/assets/img/terminal/zffs.png'),
            title: '支付方式',
            tip: '支持刷脸支付、医保支付、银行卡支付、扫码付、闪付',
          },
        ],
        prodParams: [
          {
            label: '操作系统',
            value: 'Windows 10 64位操作系统',
          },
          {
            label: '中央处理器',
            value: 'I5系列第四代处理器',
          },
          {
            label: '存储',
            value: '内存：8G DDR3 硬盘：1T 机械硬盘',
          },
          {
            label: '显示屏',
            value: '43英寸液晶显示屏，分辨率1920×1080，亮度330cd/m2',
          },
          {
            label: '触摸屏',
            value: '电容触摸屏，10点触控，钢化玻璃面板',
          },
          {
            label: '支付摄像头',
            value: '微信刷脸支付3D摄像头',
          },
          {
            label: '人脸摄像头',
            value: '单目摄像头，USB接口，200W像素',
          },
          {
            label: '电动读卡器',
            value: '支持接触式IC卡读写， 符合ISO/IEC 7816标准，支持CPU卡，存储卡，逻辑加密卡； 支持非接接触式IC卡读写， 符合ISO/IEC 14443标准，支持TypeA/B，M1卡； 支持磁条卡信息阅读， 符合 ISO7810 和 7811 标准',
          },
          {
            label: '身份证阅读器',
            value: '支持第二代居民身份证（含指纹版）， 支持外国人永久居留身份证， 支持港澳台居民居住证',
          },
          {
            label: '条码阅读器',
            value: '支持符合国内外通用的一维码与二维码',
          },
          {
            label: 'PSAM卡座',
            value: '4个，符合ISO/IEC 7816标准',
          },
          {
            label: '凭条打印机',
            value: '热敏打印，支持纸卷宽度80mm，纸卷外径80mm',
          },
          {
            label: '密码键盘',
            value: '支持标准银联金属密码键盘。 0~9 十个数字键， 小数点、00、取消、更正、确认五个功能键 一个可自定义功能键',
          },
          {
            label: '报告单打印',
            value: '激光打印机，A4黑白打印',
          },
          {
            label: '发卡器',
            value: '（选配）支持三卡或双卡功能，磁卡+接触+非接卡或接触+非接卡读写',
          },
          {
            label: '纸币器',
            value: '（选配）可接受1元、5元、10元、20元、50元、100元人民币， 可接收纸币4个方向放入； 支持针孔监控摄像头',
          },
          {
            label: 'WIFI',
            value: '支持',
          },
          {
            label: '以太网',
            value: '支持',
          },
          {
            label: '扬声器',
            value: '支持双扬声器，8Ω5W',
          },
          {
            label: '人体感应',
            value: '支持红外人体感应，感应距离：10cm-100cm',
          },
          {
            label: 'USB接口',
            value: '外置两个HOST USB接口',
          },
          {
            label: 'UPS电源',
            value: '支持UPS电源，标称容量500VA',
          },
          {
            label: '电源设计',
            value: '支持AC220V电源输入，支持F10AL 250V保险丝',
          },
          {
            label: '自动开关机',
            value: '支持自动开关机',
          },
          {
            label: '电源开关',
            value: '支持电源开关切断整机电源',
          },
        ],
      },
      {
        label: 'M300L-曲面屏',
        desc: '永不下班的“服务台”',
        imgsrc: require('@/assets/img/terminal/terminalImages/M300L_qmp.png'),
        advantageList: [
          {
            img: require('@/assets/img/terminal/mzzzfw.png'),
            title: '门诊自助服务',
            tip: '签约发卡、医生签到、自助挂号、预约取号、门诊缴费、扫码绑卡、自助查询、排队叫号',
          },
          {
            img: require('@/assets/img/terminal/zyzzfw.png'),
            title: '住院自助服务',
            tip: '押金充值、自助补缴、自助查询、护士巡房签到',
          },
          {
            img: require('@/assets/img/terminal/nbgl.png'),
            title: '内部管理',
            tip: '考勤、会议签到、食堂缴费',
          },
          {
            img: require('@/assets/img/terminal/zffs.png'),
            title: '支付方式',
            tip: '支持刷脸支付、医保支付、银行卡支付、扫码付、闪付',
          },
        ],
        prodParams: [
          {
            label: '操作系统',
            value: 'Windows 10 64位操作系统',
          },
          {
            label: '中央处理器',
            value: '第10代CPU（I5-10400）',
          },
          {
            label: '存储',
            value: '内存：8G DDR3 硬盘：256G固态硬盘',
          },
          {
            label: '显示屏',
            value: '43英寸液晶曲面屏，分辨率1920×1080，亮度330cd/m2',
          },
          {
            label: '触摸屏',
            value: '电容触摸屏，10点触控，钢化玻璃面板',
          },
          {
            label: '支付摄像头',
            value: '微信刷脸支付3D摄像头',
          },
          {
            label: '人脸摄像头',
            value: '单目摄像头，USB接口，200W像素',
          },
          {
            label: '电动读卡器',
            value: '支持接触式IC卡读写， 符合ISO/IEC 7816标准，支持CPU卡，存储卡，逻辑加密卡； 支持非接接触式IC卡读写， 符合ISO/IEC 14443标准，支持TypeA/B，M1卡； 支持磁条卡信息阅读， 符合 ISO7810 和 7811 标准',
          },
          {
            label: '身份证阅读器',
            value: '支持第二代居民身份证（含指纹版）， 支持外国人永久居留身份证， 支持港澳台居民居住证',
          },
          {
            label: '条码阅读器',
            value: '支持符合国内外通用的一维码与二维码',
          },
          {
            label: 'PSAM卡座',
            value: '4个，符合ISO/IEC 7816标准',
          },
          {
            label: '凭条打印机',
            value: '热敏打印，支持纸卷宽度80mm，纸卷外径80mm',
          },
          {
            label: '密码键盘',
            value: '支持标准银联金属密码键盘。 0~9 十个数字键， 小数点、00、取消、更正、确认五个功能键 一个可自定义功能键',
          },
          {
            label: '报告单打印',
            value: '激光打印机，A4黑白打印',
          },

          // {
          //   label: '纸币器',
          //   value: '（选配）可接受1元、5元、10元、20元、50元、100元人民币， 可接收纸币4个方向放入； 支持针孔监控摄像头',
          // },
          {
            label: 'WIFI',
            value: '支持',
          },
          {
            label: '以太网',
            value: '支持',
          },
          {
            label: '扬声器',
            value: '支持双扬声器，8Ω5W',
          },
          {
            label: '人体感应',
            value: '支持红外人体感应，感应距离：10cm-100cm',
          },
          {
            label: 'USB接口',
            value: '外置两个HOST USB接口',
          },
          {
            label: 'UPS电源',
            value: '支持UPS电源，标称容量500VA',
          },
          {
            label: '电源设计',
            value: '支持AC220V电源输入，支持F10AL 250V保险丝',
          },
          {
            label: '自动开关机',
            value: '支持自动开关机',
          },
          {
            label: '电源开关',
            value: '支持电源开关切断整机电源',
          },
        ],
      },
      {
        label: 'M300J',
        desc: '永不下班的“服务台”',
        imgsrc: require('@/assets/img/terminal/terminalImages/M300J.png'),
        prodParams: [
          {
            label: '操作系统',
            value: 'Windows 或 安卓操作系统',
          },
          {
            label: '中央处理器',
            value: 'Windows 或 安卓',
          },
          {
            label: '显示屏',
            value: '15.6 寸或 23.6 寸 TFT 液晶屏，分辨率：1920*1080',
          },
          {
            label: '触摸屏',
            value: '支持',
          },
          {
            label: '指示灯',
            value: 'LED，从下到上：蓝黄绿红',
          },
          {
            label: '密码键盘',
            value: '内置金融密码键盘',
          },
          {
            label: '蜂鸣器',
            value: '支持',
          },
          {
            label: '语音',
            value: '支持',
          },
          {
            label: '扬声器',
            value: '支持',
          },
          {
            label: '接触IC卡',
            value: '1 个，符合 ISO/IEC 7816 标准',
          },
          {
            label: '非接触卡',
            value: '符合 ISO/IEC 14443 标准，支持 TypeA/B， Mifare 卡',
          },
          {
            label: '读磁条卡',
            value: '支持 1/2/3 轨磁道阅读',
          },
          {
            label: '读二代证',
            value: '支持二代身份证信息读取',
          },
          {
            label: '卡座',
            value: 'SAM卡座4个、SIM卡座1个、TF卡座1个',
          },
          {
            label: '通讯方式',
            value: '支持以太网、WIFI、全网通、蓝牙',
          },
          {
            label: '摄像头',
            value: '单目摄像头',
          },
          {
            label: '打印机',
            value: '内置热敏打印',
          },
          {
            label: '扫码模块',
            value: '支持一二维码扫码功能',
          },
          {
            label: '实时时钟',
            value: '支持',
          },
          {
            label: '电源',
            value: '220VAC 电源',
          },
          {
            label: '工作环境',
            value: '温度：-10℃～50℃ 相对湿度：5℅～93℅',
          },
          {
            label: '存储环境',
            value: '温度：-20℃～60℃ 相对湿度：5℅～95℅',
          },
        ],
      },
      {
        label: 'M300LC',
        desc: '人机交互  智慧自助',
        imgsrc: require('@/assets/img/terminal/terminalImages/M300LC.png'),
        prodParams: [
          {
            label: '操作系统',
            value: 'window 10 64位企业版操作系统',
          },
          {
            label: '主控模块',
            value: 'CPU I5 ，8GB DDR3，256G固态硬盘',
          },
          {
            label: '液晶触摸显⽰屏',
            value: '32英⼨液晶显⽰屏，分辨率1920×1080，亮度330cd/m2',
          },
          {
            label: '⾝份证阅读器',
            value: '第⼆代居⺠⾝份证信息阅读',
          },
          {
            label: '扫码模块',
            value: '⽀持⼀、⼆维码信息阅读',
          },
          {
            label: '社保卡/银⾏卡读卡器',
            value: '⽀持',
          },
          {
            label: '⼈脸识别摄像头',
            value: 'USB双⽬摄像头，200W，宽动态',
          },
          {
            label: '密码键盘',
            value: '标准银联⾦属密码键盘',
          },
          {
            label: '热敏打印机',
            value: '80mm热敏打印机、⾃动切纸',
          },
          {
            label: '激光打印机',
            value: 'A4/A5⿊⽩激光打印机，标配250张⼤容量纸盒，扩展纸盒550张',
          },
          {
            label: 'WIFI',
            value: 'IEEE 802.11b/g/n',
          },
          {
            label: '以太⽹',
            value: '⽀持10/100/1000Mbps ⾃适应⽹络接⼝',
          },
          {
            label: '⾳响',
            value: '功率放⼤器，⽀持双扬声器',
          },
          {
            label: '⼈体感应接近器',
            value: '可感知⼈体接近状态，红外感应技术',
          },
          {
            label: '⼈机提⽰',
            value: '⾃助功能模块LED灯光控制，提醒⽅便⽤⼾使⽤',
          },
        ],
      },
    ],
  },
  {
    label: '综合产品终端',
    children: [
      {
        label: 'T10-MX4',
        desc: '嵌入式多功能模块',
        imgsrc: require('@/assets/img/terminal/terminalImages/T10-MX4.png'),
        prodParams: [
          {
            label: '可支持操作系统',
            value: 'Windows XP、7、8、10，Linux，安卓',
          },
          {
            label: '中央处理器',
            value: '32 位处理器',
          },
          {
            label: '指示灯',
            value: '4个，从左到右：蓝黄绿红（选配）',
          },
          {
            label: '蜂鸣器',
            value: '支持，无源',
          },
          {
            label: '通讯方式',
            value: 'RS232',
          },
          {
            label: '磁卡读卡器',
            value: '1/2/3 轨磁道阅读，双向刷卡，单面刷卡（选配）',
          },
          {
            label: 'IC卡读卡器',
            value: '1个，符合 ISO/IEC7816标准的异步卡、同步卡（选配）',
          },
          {
            label: '非接触卡读写器',
            value: '符合ISO/IEC 14443标准，支持TypeA/B，Mifare卡',
          },
          {
            label: 'SAM 卡座',
            value: '4个，符合ISO/IEC7816标准',
          },
          {
            label: '二代身份证模块',
            value: '支持第二代居民身份证信息读取（选配）',
          },
          {
            label: '扫码模块',
            value: '支持国内外常用的一二维码扫码（选配）',
          },
          {
            label: '电源',
            value: 'USB口供电，DC5V±5% 输入电源电流需满足900MA',
          },
          {
            label: '其他特性',
            value: '提供通用接口函数库，可支持多种操作系统和语言开发平台；提供设备端的二次开发平台',
          },
          {
            label: '工作环境',
            value: '温度：-10℃～50℃；相对湿度：5℅～93℅（非冷凝）',
          },
          {
            label: '存储环境',
            value: '温度：-20℃～60℃；相对湿度：5℅～95℅（非冷凝）',
          },
        ],
      },
      {
        label: 'T10EM2',
        desc: '嵌入式多功能电动读写终端',
        imgsrc: require('@/assets/img/terminal/terminalImages/T10EM2.png'),
        prodParams: [
          {
            label: '支持操作系统',
            value: 'Windows 32位/64位、Unix 以及 Linux',
          },
          {
            label: '磁条卡',
            value: '1/2/3 轨磁道阅读，通过标准磁卡和极限卡的测试',
          },
          {
            label: 'IC卡',
            value: '主卡座支持符合 ISO7816 协议的 T=0 、T=1 的 CPU卡的操作',
          },
          {
            label: '非接触卡',
            value: '支持ISO14443TypeA标准非接触卡型 ，射频天线内置外置可选',
          },
          {
            label: '通信接口',
            value: 'RS232',
          },
          {
            label: 'SAM卡槽',
            value: '选配/支持GSM11.11标准SAM卡座，最多可选4个',
          },
          {
            label: '走卡速度',
            value: '≈350mm/s',
          },
          {
            label: '卡片规格',
            value: '宽度：53.98mm，长度 85.6mm，厚度:0.6～1.2mm（出厂标准 0.8mm）',
          },
          {
            label: '振动',
            value: '频率范围：5～50Hz；加速度：2m/s2（0.2G）',
          },
          {
            label: '寿命',
            value: '传动：100 万次以上，磁头 100 万次以上，IC 触点 50 万次以上',
          },
          {
            label: '电机控制',
            value: '具有自动吸卡，受控进、退、吞卡的功能',
          },
          {
            label: '特殊处理',
            value: '可处理均匀变形高度小于 2mm 的卡片',
          },
          {
            label: '电源及功耗',
            value: '电源 DC 12V±5%；功耗：静态电流：小于 150mA；峰值电流：小于 1500mA',
          },
          {
            label: '二次开发',
            value: '随机提供通用接口函数库，可支持多种操作系统和语言开发平台',
          },
          {
            label: '符合规范',
            value: 'PBOC3.0L1、EMV L1、Qpboc3.0 L1、EMV Contactless L1、人社部、居民健康卡',
          },
          {
            label: '工作环境',
            value: '温度：-20℃～50℃；相对湿度：10℅～90℅（非冷凝）',
          },
        ],
      },
      {
        label: 'P3',
        desc: '密码键盘',
        imgsrc: require('@/assets/img/terminal/terminalImages/P3.png'),
        prodParams: [
          {
            label: '支持系统',
            value: 'Windows、Linux',
          },
          {
            label: '存储',
            value: 'FRAM：8KByte FLASH：2MByte',
          },
          {
            label: '按键',
            value: '数字键：0～9 功能键：清除、确认、F1、F2、F3',
          },
          {
            label: '指示灯',
            value: '2个：红色、绿色',
          },
          {
            label: '液晶',
            value: '可显示2行每行8个汉字（16个字符） 支持GB2312汉字字库（选配）',
          },
          {
            label: '语音',
            value: '支持语音播报',
          },
          {
            label: '蜂鸣器',
            value: '支持',
          },
          {
            label: '时钟',
            value: '支持',
          },
          {
            label: '通讯接口',
            value: 'RS232，RJII接头',
          },
          {
            label: '加密',
            value: 'DES、3DES加密，16组主密钥，每个主密钥有4组工作密钥',
          },
          {
            label: '取电方式',
            value: 'USB',
          },
          {
            label: '电压',
            value: '5V',
          },
          {
            label: 'PSAM卡接口',
            value: '标准：GSM11.11、数量2（选配）',
          },
          {
            label: '存储卡',
            value: 'SLE4442，SLE4428，AT24C01，AT24C02， AT24C04，AT24C08，AT24C16，AT24C64，AT88SC102，AT88SC1604，AT45DB041',
          },
          {
            label: '支持卡型',
            value: '标准 ISO/IEC 7816、卡型T=0、T=1，卡座数量1个（选配）',
          },
          {
            label: '射频',
            value: '标准：ISO 14443 Type A/B，Mifare（选配）',
          },
          {
            label: '符合标准',
            value: '人社部、住建部、卫计委',
          },
          {
            label: '工作环境',
            value: '温度：-20ºC～50ºC；相对湿度：5%～93%（非冷凝）',
          },
          {
            label: '存储环境',
            value: '温度：20ºC～60ºC；相对湿度：5%～95%（非冷凝）',
          },
        ],
      },
      {
        label: 'P6',
        desc: '密码键盘',
        imgsrc: require('@/assets/img/terminal/terminalImages/P6.png'),
        prodParams: [
          {
            label: '支持操作系统',
            value: 'WIN，安卓，Linux',
          },
          {
            label: '中央处理器',
            value: '32位处理器',
          },
          {
            label: '显示屏',
            value: 'LCD，点阵192*64',
          },
          {
            label: '指示灯',
            value: '从左到右：蓝黄绿红',
          },
          {
            label: '按键',
            value: '16个键值，F1,F2,F3,0,1,2,3,4,5,6,7,8,9,取消,退格,确认',
          },
          {
            label: '蜂鸣器',
            value: '支持',
          },
          {
            label: '物理接口及通讯方式',
            value: 'RS232通讯，RJ11接口',
          },
          {
            label: '实时时钟',
            value: '支持',
          },
          {
            label: '电源',
            value: 'DC5V±5%',
          },
          {
            label: '安全特性',
            value: '防拆，防入侵，防监听',
          },
          {
            label: '算法支持',
            value: 'DES、RSA、TDES等，国密算法',
          },
          {
            label: '功能支持',
            value: 'PIN加密，MAC运算，数据加密/解密',
          },
          {
            label: '支持操作系统',
            value: 'WIN，安卓，Linux',
          },
          {
            label: '工作环境',
            value: '工作温度：-10°~+50°；工作湿度：5%~93%',
          },
          {
            label: '存储环境',
            value: '存储湿度：-20°~+60°；存储湿度：5%~95%',
          },
        ],
      },
      {
        label: 'F11六合一',
        desc: '多媒体智能终端',
        imgsrc: require('@/assets/img/terminal/terminalImages/F11_lhy.png'),
        advantageList: [
          {
            img: require('@/assets/img/terminal/tx.png'),
            title: '体系',
            tip: '医疗保障局：管理、监管;医保实验室：标准、运维终端;服务商：接入、售后',
          },
          {
            img: require('@/assets/img/terminal/sjff.png'),
            title: '规范',
            tip: '《医保业务综合服务终端（III类）技术规范》',
          },
          {
            img: require('@/assets/img/terminal/htjc.png'),
            title: '活体检测',
            tip: '具有快速识别人脸，活体检测功能，判断识别，高效便捷',
          },
          {
            img: require('@/assets/img/terminal/zs.png'),
            title: '证书',
            tip: 'III类终端设备测试报告；NO.JC01202000001、CCC、CTA、SRRC、ROHS',
          },
          {
            img: require('@/assets/img/terminal/aq.png'),
            title: '安全',
            tip: '一机一密、拆机失效;可信操作、安全模块;VPN通讯、位置信息',
          },
          {
            img: require('@/assets/img/terminal/yzjh.png'),
            title: '优质交互',
            tip: '多点触摸屏',
          },
        ],
        prodParams: [
          {
            label: '操作系统',
            value: 'Android 9.0',
          },
          {
            label: '处理器',
            value: '四核Cortex-A53，频率2.0GHz',
          },
          {
            label: '存储器',
            value: '2GB LPDDR+ 16GB eMMC',
          },
          {
            label: '显示屏',
            value: '8寸TFT液晶屏，800*1280',
          },
          {
            label: '触摸屏',
            value: '电容屏五点触摸，全贴合设计',
          },
          {
            label: '摄像头',
            value: '3D结构光摄像头',
          },
          {
            label: '指示灯',
            value: '四个LED指示灯，蓝黄绿红',
          },
          {
            label: '按键',
            value: '标准安卓电源按键：开关机/重启；标准音量加、减按键',
          },
          {
            label: '蜂鸣器',
            value: '支持',
          },
          {
            label: '语音',
            value: '支持',
          },
          {
            label: '扬声器',
            value: '支持',
          },
          {
            label: '通讯接口',
            value: 'USB、RJ11、RJ45、TYPEC',
          },
          {
            label: '非接触卡读写器',
            value: '符合ISO/IEC 14443标准，支持TypeA/B， Mifare卡',
          },
          {
            label: 'IC卡读卡器',
            value: '符合ISO/IEC 7816标准。支持异步卡如T=0、T=1的CPU卡，同步卡如常用的存储卡AT24系列、4442、4428等卡型',
          },
          {
            label: '二代证',
            value: '支持第二代居民身份证信息阅读功能（选配）',
          },
          {
            label: '条码模块',
            value: '支持常用一二维码识读',
          },
          {
            label: '卡座',
            value: '4 个PSAM卡座，1 个SIM卡座，1 个TF卡座',
          },
          {
            label: '通讯方式',
            value: '蓝牙、WIFI、以太网、全网通',
          },
          {
            label: '实时时钟',
            value: '支持',
          },
          {
            label: '电源',
            value: '支持标准 DC12V电源输入',
          },
          {
            label: '工作环境',
            value: '温度：-10℃～50℃；相对湿度：20%～90%（无冷凝）',
          },
          {
            label: '存储环境',
            value: '温度：-40℃～70℃；相对湿度：20%～90%（无冷凝）',
          },
        ],
      },
      {
        label: 'F10-X1',
        desc: '人脸识别核验终端',
        imgsrc: require('@/assets/img/terminal/terminalImages/F10-X1.png'),
        advantageList: [
          {
            img: require('@/assets/img/terminal/tx.png'),
            title: '性能强大',
            tip: '采用高性能CPU和大容量内存，性能强大，不卡顿不延迟',
          },
          {
            img: require('@/assets/img/terminal/sjff.png'),
            title: '数据丰富',
            tip: '丰富的API 接口，可快速加载支付和行业应用',
          },
          {
            img: require('@/assets/img/terminal/htjc.png'),
            title: '活体检测',
            tip: '具有快速识别人脸，活体检测功能，判断识别，高效便捷',
          },
          {
            img: require('@/assets/img/terminal/yxzc.png'),
            title: '优秀支持',
            tip: '支持非接触卡、接触式读卡写，二代身份证读取',
          },
          {
            img: require('@/assets/img/terminal/aq.png'),
            title: '万物互联',
            tip: '支持以太网、蓝牙、WIFI、4G网全网通多种通讯方式',
          },
          {
            img: require('@/assets/img/terminal/yzjh.png'),
            title: '优质交互',
            tip: '采用高品质触控屏，触觉手感好，采用人体工程学设计，尽显高雅',
          },
        ],
        prodParams: [
          {
            label: '操作系统',
            value: 'Android 9.0',
          },
          {
            label: '处理器',
            value: '四核Cortex-A53，频率2.0GHz',
          },
          {
            label: '存储器',
            value: '2GB LPDDR4+ 16GB eMMC',
          },
          {
            label: '显示屏',
            value: '8寸TFT液晶屏，800*1280，IPS，MIPI接口',
          },
          {
            label: '摄像头',
            value: '支持RGB+IR双目摄像头，200W，支持可见光补光和红外补光',
          },
          {
            label: '指示灯',
            value: '三色LED指示灯条，白绿红',
          },
          {
            label: '蜂鸣器',
            value: '支持，无源',
          },
          {
            label: '语音',
            value: '支持，可自定义',
          },
          {
            label: '扬声器',
            value: '支持，双扬声器接口，一个内置，一个外接',
          },
          {
            label: '物理接口',
            value: 'USB×1、RS232×2、TTL×2、继电器×1',
          },
          {
            label: '通讯方式',
            value: '以太网、蓝牙、WIFI',
          },
          {
            label: '实时时钟',
            value: '支持实时时钟，掉电保存',
          },
          {
            label: '电源',
            value: '支持标准 DC12V电源输入， 支持过压保护，支持上电开机',
          },
          {
            label: '看门狗',
            value: '支持',
          },
          {
            label: '防尘防水',
            value: '具备防淋雨',
          },
          {
            label: '工作环境',
            value: '温度：-20℃～70℃；相对湿度：20%～90%（无冷凝）',
          },
          {
            label: '存储环境',
            value: '温度：-30℃～70℃；相对湿度：20%～90%（无冷凝）',
          },
        ],
      },
      {
        label: 'P18F',
        desc: '支持银联云闪付、二维码支付',
        imgsrc: require('@/assets/img/terminal/terminalImages/P18F.png'),
        prodParams: [
          {
            label: '操作系统',
            value: 'Android',
          },
          {
            label: '中央处理器',
            value: '高通SDM450 ARM Cortex-A53 64位处理器（八核），1.8GHz',
          },
          {
            label: '存储器',
            value: '2GB LPDDR3+16GB eMMC',
          },
          {
            label: '显示屏',
            value: '主显：8寸TFT液晶屏，1280*800，IPS全视角，MIPI接口；次显：4.3寸TFT液晶屏，800*480，IPS全视角，RGB接口',
          },
          {
            label: '触摸屏',
            value: '主显区域支持触摸功能。 电容屏，五点触摸，G+G全贴合设计，无实体按键设计',
          },
          {
            label: '扫码模块',
            value: '支持常见一、二维码',
          },
          {
            label: '非接触卡读写器',
            value: '符合ISO/IEC 14443标准，支持TypeA/B， Mifare卡， 支持ISO14443 /1/2/3/4 T=CL协议， 工作频率13.56MHz±7kHz',
          },
          {
            label: '卡座',
            value: 'SAM卡座4个、SIM卡座2个、TF卡座1个',
          },
          {
            label: '键盘',
            value: '19键',
          },
          {
            label: '指示灯',
            value: '可控LED指示灯4个，从左到右：蓝黄绿红',
          },
          {
            label: '蜂鸣器',
            value: '支持，无源',
          },
          {
            label: '语音',
            value: '支持，可自定义',
          },
          {
            label: '通讯方式',
            value: '蓝牙、WIFI、以太网、全网通',
          },
          {
            label: '实时时钟',
            value: '支持',
          },
          {
            label: '电源',
            value: '标准DC9V-24V',
          },
          {
            label: '工作环境',
            value: '温度：-10℃～50℃；相对湿度：5℅～95℅（非冷凝）',
          },
          {
            label: '存储环境',
            value: '温度：-20℃～60℃；相对湿度：5℅～95℅（非冷凝）',
          },
          {
            label: '电池',
            value: '3.7V5000mAH',
          },
        ],
      },
      {
        label: 'P30-C扫码墩',
        desc: '扫码如此方便',
        imgsrc: require('@/assets/img/terminal/terminalImages/P30-C.png'),
        prodParams: [
          {
            label: '中央处理器',
            value: '32位安全处理器',
          },
          {
            label: '摄像头',
            value: '640（水平） X 480（垂直）CMOS，30fps',
          },
          {
            label: '视角',
            value: '水平53°，垂直41°',
          },
          {
            label: '识读角度',
            value: '旋转360°，倾斜±45°，偏转±45°',
          },
          {
            label: '蜂鸣器',
            value: '支持',
          },
          {
            label: '物理接口',
            value: 'USB；HID-KBW（默认）',
          },
          {
            label: '扫码模块',
            value: '支持常见的一二维码扫码功能',
          },
          {
            label: '电源',
            value: '5V',
          },
          {
            label: '电流',
            value: '450mA',
          },
          {
            label: '散热',
            value: '支持',
          },
          {
            label: '线长',
            value: '1.5米',
          },
          {
            label: '外观尺寸',
            value: '130mm L*109mm W*111.5mm H',
          },
          {
            label: '工作环境',
            value: '温度：-20℃～55℃ 存储温度：-40℃-70℃ 工作湿度：5%RH~95%RH（不凝结）',
          },
        ],
      },
      {
        label: 'P18Q',
        desc: '支持银联云闪付、二维码支付',
        imgsrc: require('@/assets/img/terminal/terminalImages/P18Q.png'),
        prodParams: [
          {
            label: '操作系统',
            value: 'Android 9.0',
          },
          {
            label: '中央处理器',
            value: 'KunT11，四核Cortex-A53，频率2.0GHz',
          },
          {
            label: '片外存储',
            value: '2GB LPDDR4+ 16GB eMMC',
          },
          {
            label: '显示屏',
            value: '6.95寸，TFT，600×1024，LVDS接口',
          },
          {
            label: '触摸屏',
            value: 'G+G方式，电容触摸屏，五点触控',
          },
          {
            label: '背显屏',
            value: '4段数码管，红光（背面）',
          },
          {
            label: '指示灯',
            value: '正面：从左到右：蓝黄绿红，可控；背面：从上到下：绿红，可控',
          },
          {
            label: '扫码指示灯带',
            value: '灯条，蓝色',
          },
          {
            label: '非接指示灯',
            value: '按非接区域设计，绿色',
          },
          {
            label: '按键',
            value: '1个电源键。 四个功能按键（F1、F2、F3、F4)',
          },
          {
            label: '蜂鸣器',
            value: '无源，可控',
          },
          {
            label: '语音',
            value: '支持',
          },
          {
            label: '扬声器',
            value: '支持',
          },
          {
            label: '通讯接口',
            value: 'RS232，HDMI接口，USB',
          },
          {
            label: '非接触卡读写器',
            value: '符合ISO/IEC 14443标准，支持TypeA/B， Mifare卡',
          },
          {
            label: '二代证',
            value: '支持，小型二代证安全模块（选配）',
          },
          {
            label: '卡座',
            value: 'SAM卡座4个，SIM卡座1个，TF卡座1个',
          },
          {
            label: '扫码模块',
            value: '支持常见一二维码读取',
          },
          {
            label: '通讯方式',
            value: '蓝牙、WIFI、全网通、以太网',
          },
          {
            label: '定位',
            value: '支持GPS/北斗/GLONASS',
          },
          {
            label: '实时时钟',
            value: '支持，带时钟纽扣电池',
          },
          {
            label: '电源',
            value: '宽压：9～48V±5%，具有反接、过压过流自动保护自动恢复等功能',
          },
          {
            label: '掉电保护',
            value: '支持掉电保护功能。即车载机外部输入电源异常断电后，支持1-2S保护数据存储',
          },
          {
            label: '工作环境',
            value: '温度：-20℃～70℃；相对湿度：5℅～93℅（非冷凝）',
          },
          {
            label: '存储环境',
            value: '温度：-30℃～80℃；相对湿度：5℅～93℅（非冷凝）',
          },
        ],
      },
      {
        label: 'D8',
        desc: '轻松一放即可读写',
        imgsrc: require('@/assets/img/terminal/terminalImages/D8.png'),
        prodParams: [
          {
            label: '支持系统',
            value: 'Window XP、7、8、10,Linux',
          },
          {
            label: '指示灯',
            value: 'LED指示灯，指示电源或通讯状态',
          },
          {
            label: '其他特性',
            value: '提供通用接口函数库，可支持多种操作系统和语言开发平台,支持定制开发',
          },
          {
            label: '通讯线',
            value: '附1.5M长的连接通讯线',
          },
          {
            label: '工作环境',
            value: '温度:-10°C~50°C;相对湿度:5%~93%(非冷凝)',
          },
          {
            label: 'SAM卡座',
            value: ' 3个',
          },
          {
            label: '存储环境',
            value: '温度:-20°C~60°C;相对湿度:5%~95%(非冷凝)',
          },
          {
            label: '通讯接口',
            value: ' USB、RS232',
          },
          {
            label: '接触式卡',
            value: '支持ISO14443 TypeA/B标准感应IC卡',
          },
          {
            label: '非接触卡',
            value: '1个电源键。 四个功能按键（F1、F2、F3、F4)',
          },
          {
            label: '蜂鸣器',
            value: '无源，可控',
          },
          {
            label: '语音',
            value: '支持',
          },
          {
            label: '扬声器',
            value: '支持',
          },
          {
            label: '通讯接口',
            value: 'RS232，HDMI接口，USB',
          },
          {
            label: '非接触卡读写器',
            value: '符合ISO/IEC 14443标准，支持TypeA/B， Mifare卡',
          },
        ],
      },
      {
        label: 'T6',
        desc: '人体工学设计，小巧灵活',
        imgsrc: require('@/assets/img/terminal/terminalImages/T6.png'),
        prodParams: [
          {
            label: '操作系统',
            value: 'Windows XP、7、8、10，Linux',
          },
          {
            label: '指示灯',
            value: '可选配2个或4个LED指示灯',
          },
          {
            label: '存储环境',
            value: '温度:-20°C~60°C;相对湿度:5%~95%(非冷凝)',
          },
          {
            label: '其他特性',
            value: '提供通用接口函数库，可支持多种操作系统和语言开发平台',
          },
          {
            label: '外置接口',
            value: '支持外接密码键盘',
          },
          {
            label: '蜂鸣器',
            value: '支持',
          },
          {
            label: '通讯接口',
            value: 'RS232、USB',
          },
          {
            label: '接触式卡',
            value: '支持1个ISO7816标准的IC卡',
          },
          {
            label: '非接触卡',
            value: '支持ISO14443 TypeA/B标准感应IC卡',
          },
          {
            label: 'SAM卡座',
            value: '2个',
          },
          {
            label: '电源',
            value: 'DC 5V',
          },
          {
            label: '工作环境',
            value: '温度:-10°C~50°C;相对湿度:5%~93%(非冷凝)',
          },
        ],
      },
    ],
  },
]
