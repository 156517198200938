// 智慧医疗解决方案数据
export const smartSocialSecurityList = [
  {
    label: '数字人社应用方案',
    children: [
      {
        index: '01',
        label: '青海人社双屏机就近办业务',
        imgsrc: require('@/assets/img/smartSocialSecurity/qhspj.svg'),
        description: '<p>青海人社双屏机是一种创新的自助服务设备，具有双屏显示和人机互动功能，让市民在就近的社会保障服务等场所即可轻松办理人社业务。无论您需要查询社保信息、缴纳医保费用，还是办理退休手续、申请就业扶持等，只需在双屏机上简单操作，即可轻松完成。</p>',
        text: `<span index="1">青海人社双屏机，实现了以下便利</span>
        <p><b>就近办理：</b>不必再长途跋涉到人社部门排队等待，您可以在家附近的社区、银行、医院等场所使用双屏机办理业务，省时省力。</p>
        <p><b>自助操作：</b>双屏机操作简单直观，可以根据自己的需求选择服务项目，按照屏幕提示进行操作，轻松完成业务办理。</p>
        <p><b>高效便捷：</b>双屏机支持多种支付方式，包括银行卡、微信、支付宝等，在办理业务时无需携带大量现金和银行卡。</p>
        <p><b>安全可靠：</b>双屏机采用先进的安全技术，保护您的个人信息安全和隐私，确保您的数据安全可靠。</p>
        <p>青海人社双屏机的使用也提高了市民的获得感和满意度。市民可以自主选择服务项目，按照屏幕提示进行操作，简单易懂，轻松完成业务办理。这种自助服务的方式让市民感受到了更多的自主权和便利性，同时也提高了办事的私密性和安全性。</p>
        <p>其次，青海人社双屏机的推出也促进了社会的发展和进步。它不仅方便了市民的生活，也提高了政府部门的办事效率和服务质量。这种创新的自助服务方式为政府和社会带来了更多的机遇和挑战，推动了社会的数字化转型和升级。</p>
        `,
        imgurl: [require('@/assets/img/smartSocialSecurity/qhspj_1.png'), require('@/assets/img/smartSocialSecurity/qhspj_2.png')],
      },
      {
        index: '02',
        label: '甘肃人社酒泉景区应用',
        imgsrc: require('@/assets/img/smartSocialSecurity/jqrsjq.svg'),
        description: '<p>甘肃人社酒泉景区应用是甘肃省人社厅与酒泉景区合作打造的一款创新型旅游服务应用。它依托于人社部门丰富的社保信息和景区独特的服务资源，为游客提供便捷、高效、安全的旅游体验。</p>',
        text: `<span index="2">甘肃人社酒泉景区应用，实现了以下特色服务</span>
        <p><b>旅游资讯获取：</b>轻松获取酒泉景区的各类信息，包括景点介绍、门票价格、旅游路线等，使旅行更加便捷。</p>
        <p><b>在线购票：</b>支持在线购票功能，让您随时随地购买酒泉景区的门票，省去排队等待的时间。</p>
        <p><b>景区验票：</b>进入景区凭借社保卡可在闸机上轻松验票，无需实体票便可入园。</p>
        <p><b>游客分析：</b>提供各个景区游客数据分析，通过游客数量统计、游客来源分析、数据可视化展示为景区管理者和相关部门提供关于游客行为的深入洞察，以支持更好的决策和改进。</p>
        <p>甘肃人社酒泉景区应用还为景区带来了可持续发展的机遇。通过与社保信息的深度融合和创新应用，景区可以不断优化自身的服务和管理模式，提高自身的竞争力和品牌形象。同时，该应用也为景区提供了与政府部门、企业和社会组织等多方合作的机遇，拓展了景区的发展空间和资源渠道，为景区的可持续发展注入了新的动力。</p>
        `,
        imgurl: [require('@/assets/img/smartSocialSecurity/jqrsjq_1.png')],
      },
      {
        index: '03',
        label: '宁夏人社职业学校签到系统',
        imgsrc: require('@/assets/img/smartSocialSecurity/nxrszyxx.svg'),
        description: '<p>宁夏人社职业学校签到系统是一款创新型的教育信息化产品，它依托于人社部门丰富的信息资源，为学校提供高效、便捷的签到体验。该系统采用先进的物联网技术，实现了自动化、智能化的签到管理，提高了签到的准确性和效率。</p>',
        text: `<span index="3">宁夏人社职业学校签到系统为学校和广大师生提供了以下服务</span>
        <p><b>便捷签到：</b>通过自动化、智能化的签到管理，减少了繁琐的人工操作，提高了签到的效率。师生可以在自助终端进行签到操作，省时省力。</p>
        <p><b>数据分析：</b>系统可生成详细的签到数据报表，帮助学校对签到情况进行全面分析，为课程考核和管理提供有力支持。</p>
        <p><b>安全监管：</b>系统采用了严格的加密技术和安全措施，保障了师生个人信息的隐私和安全。</p>
        <p>宁夏人社职业学校签到系统的使用非常简单，只需在手机应用商店或电脑浏览器搜索“宁夏人社职业学校签到系统”并下载安装，或扫描终端设备上的二维码即可使用。无论是教师还是学生，都可以通过该系统轻松完成签到操作。</p>
        <p>宁夏人社职业学校签到系统的建设效果主要体现在提高签到效率、防止漏签现象、促进信息化管理、增强安全可靠性、提升教学质量、增强学生参与度、辅助教学管理以及提升学校形象等方面。通过该系统的应用，可以有效地提高学校的管理水平和教学质量，为学校的可持续发展注入新的动力。</p>
        `,
        imgurl: [require('@/assets/img/smartSocialSecurity/nxrszyxxqdxt_1.png')],
      },
      {
        index: '04',
        label: '陕西铜川公共就业应用',
        imgsrc: require('@/assets/img/smartSocialSecurity/sxtcgghy.svg'),
        description: '<p>为了提升陕西铜川公共就业服务的效率和便利性，本项目旨在通过引入自助机、高拍仪、读卡器等智能设备，为广大求职者和招聘单位提供全方位、一站式的就业服务平台。这些设备的应用将改变传统就业服务的模式，适应现代社会快节奏、高效率的需求，使求职和招聘过程更加便捷、透明。</p>',
        text: `<span index="4">项目目标</span>
        <p><b>提高就业服务效率和质量：</b>通过自助终端设备，实现就业相关业务的自动化，减少人工操作，提高服务效率和质量。</p>
        <p><b>提升求职者和招聘单位的满意度：</b>通过提供便捷、高效的服务，降低求职者和招聘单位的成本和时间消耗，提升其对就业服务的满意度。</p>
        <p><b>保障信息安全和隐私保护：</b>采用高安全性的读卡器和加密技术，确保求职者和招聘单位的信息安全和隐私保护。</p>
        <p><b>促进就业市场的健康发展：</b>通过提供准确的就业信息和政策解读，帮助求职者找到合适的岗位，同时为招聘单位提供优质的人才资源，推动就业市场的健康发展。</p>
        `,
        imgurl: [require('@/assets/img/smartSocialSecurity/sxtcggjyyy_1.png')],
      },
    ],
  },
]
