<template>
  <div class="pageHeader">
    <div class="header_left">
      <!-- <img v-show="routeName === 'index'" src="@/assets/img/home/logo.svg" @click="toIndex" /> -->
      <img src="../assets/img/home/logo_noHome.svg" @click="toIndex" />
    </div>
    <div class="header_right">
      <div class="header_tabBox">
        <div class="header_tab" v-for="(item, index) in tabList" :ref="'tab' + index" :key="index" @click="tabChange(item, index)" @mouseover="tabHandleEnter('in', item)" @mouseout="tabHandleEnter('out', item)" :class="checkedIndex == index ? ' checked' : ''">
          {{ item.label }}
          <span></span>
        </div>
      </div>

      <div v-if="currdropPanelData" class="drop-panel" ref="dropPanel" v-show="reload">
        <div class="category" v-if="currdropPanelData.type === 1">
          <div class="subTitle" :class="currentSubMenuIndex === col ? 'checked' : ''" v-for="(data, col) in currdropPanelData.data" @mouseenter="handleEnter(data.children, col)">
            {{ data.label }}
          </div>
        </div>
        <div class="content" v-if="currdropPanelData.type === 1">
          <div class="content-item" v-for="dataItem in currentSubMenu" :key="dataItem.label" @click="handleClick(dataItem)">
            <img :src="dataItem.imgsrc" />
            {{ dataItem.label }}
          </div>
        </div>
        <div v-for="(data, col) in currdropPanelData.data" v-if="currdropPanelData.type !== 1" :key="data.label" :style="`flex: ${currdropPanelData.col[col]};display:flex`">
          <div style="width: 100%">
            <div class="title">{{ data.label }}</div>
            <div class="grid" :class="`grid-col-${currdropPanelData.col[col]}`">
              <div v-for="dataItem in data.children" :key="dataItem.label" class="grid-item" @click.self="handleClick(dataItem)">
                <img :src="dataItem.imgsrc" />
                {{ dataItem.label }}
              </div>
            </div>
          </div>
          <div class="image" v-if="currdropPanelData.image">
            <img v-for="(img, ii) in currdropPanelData.image" :key="ii" :src="img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tabList } from '@/assets/data'
export default {
  data() {
    return {
      checkedIndex: -1,
      reload: true,
      currentSubMenu: [],
      currentSubMenuIndex: 0,
      routeName: 'index',
      currdropPanelData: {},
    }
  },
  computed: {
    tabList() {
      return tabList
    },
  },
  watch: {
    $route(to, from) {
      this.routeName = to.name
      const item = this.tabList.find((v) => v.url == to.path)
      this.checkedIndex = item ? item.index : -1
    },
  },

  created() {
    this.currentSubMenu = tabList[1].dropPanel?.data[this.currentSubMenuIndex].children
  },
  mounted() {
    this.addEvnFn()
  },
  methods: {
    tabHandleEnter(str, v) {
      if (str === 'in') {
        this.currdropPanelData = v.dropPanel
      }
      let strs = ['首页', '关于我们']
      // strs.indexOf(v.label) < 0 && this.changeMask(str)
    },
    addEvnFn() {
      // document.addEventListener('mousemove', function (event) {
      //   console.log(event) // 在控制台输出鼠标所在的元素
      // })
    },
    // 增加-取消
    changeMask(str) {
      let element = document.getElementsByClassName('pageview')
      str === 'in' ? element[0].classList.add('mask') : element[0].classList.remove('mask')
    },
    handleEnter(item, index) {
      this.currentSubMenu = item
      this.currentSubMenuIndex = index
    },
    handleClick(dataItem) {
      this.currdropPanelData.onClick(this, dataItem.label)
      this.reload = false
      setTimeout(() => {
        this.reload = true
      }, 500)
    },
    toIndex() {
      this.$router.push('/')
      this.checkedIndex = -1
    },
    tabChange(item, index) {
      if (item.dropPanel) {
        return
      }
      if (item.url) {
        this.$router.push({ path: item.url })
      } else {
        this.$router.push('/')
      }
    },
  },
}
</script>

<style lang="less">
.pageHeader {
  height: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  position: fixed;
  top: 0;
  z-index: 333;
  border-bottom: 0.01rem solid #e1eaf5;
  .header_left {
    display: flex;
    width: 4.42rem;
    height: 0.34rem;
    margin: 0.23rem 0.6rem;
    cursor: pointer;
    img {
      display: block;
      // width: 100%;
    }
  }
  .header_right {
    position: relative;
    .header_tabBox {
      align-items: center;
      height: 100%;
      font-size: 0.2rem;
      font-weight: 400;
      color: #000000;
      display: flex;
    }
    &:hover {
      .drop-panel {
        transition: max-height 200ms linear;
        max-height: 4.4rem;
      }
    }
    .header_tab {
      display: flex;
      min-width: 0.9rem;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;
      cursor: pointer;
      margin: 0 0.2rem;
      padding: 0.3rem 0.12rem;
      span {
        width: 0;
        left: 50%;
      }
      &:hover {
        // color: #00a4ea;
        span {
          display: inline-block;
          width: calc(100% - 0.24rem);
          height: 0.03rem;
          position: absolute;
          bottom: 0.15rem;
          left: 0.13rem;
          background: #00a4ea;
          transition: left 0.4s, width 0.4s;
        }
      }
    }
    .drop-panel {
      transition: max-height 200ms linear;
      position: fixed;
      top: 0.8rem;
      left: 0;
      max-height: 0;
      width: 100vw;
      background-color: #ffffff;
      // animation-duration: 500ms;
      // animation-fill-mode: forwards;
      // animation-timing-function: linear;
      padding: 0 3.57rem;
      box-sizing: border-box;
      overflow: hidden;
      column-gap: 0.2rem;
      box-shadow: 0 0.1rem 0.2rem 0.01rem rgba(0, 0, 0, 0.06);
      // animation-name: fade-out;
      @keyframes fade-in {
        0% {
          height: auto;
          opacity: 0;
        }
        50% {
          height: auto;
          opacity: 0.5;
        }
        100% {
          height: auto;
          opacity: 1;
          box-shadow: -0.2rem 0.2rem 0.2rem 0.01rem rgba(99, 99, 99, 0.3);
        }
      }
      @keyframes fade-out {
        0% {
          height: 0;
          opacity: 1;
          box-shadow: -0.2rem 0.2rem 0.2rem 0.01rem rgba(99, 99, 99, 0.3);
        }
        50% {
          height: 0;
          opacity: 0.5;
        }
        100% {
          height: 0;
          opacity: 0;
        }
      }

      display: flex;

      .title {
        color: #000000;
        font-weight: bold;
        font-size: 0.2rem;
        margin-top: 0.3rem;
        padding-left: 0.2rem;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid #e1eaf5;
      }
      .grid {
        display: grid;
        padding: 0.15rem 0.2rem;
        margin-bottom: 0.3rem;
        row-gap: 0.3rem;
        .grid-item {
          // width: 1.6rem;
          max-width: 2.5rem;
          white-space: nowrap;
          height: 0.3rem;
          display: flex;
          align-items: center;
          font-size: 0.18rem;
          padding: 0.05rem 0.13rem;
          color: #000000;
          cursor: pointer;
          img {
            width: 0.2rem;
            height: 0.2rem;
            margin-right: 0.15rem;
          }
          &:hover {
            color: #00a4ea;
            background-color: rgba(0, 164, 234, 0.1);
            fill: #00a4ea;
          }
        }
      }
      .image {
        // display: flex;
        width: 4.4rem;
        column-gap: 0.5rem;
        align-items: center;
        padding: 0 0.3rem;
        margin-top: 0.4rem;
        img {
          width: 3.4rem;
          height: 1.46rem;
          margin: 0.3rem 0 0 0;
        }
      }
      .grid-col-3 {
        grid-template-columns: repeat(3, 1fr);
      }
      .grid-col-2 {
        grid-template-columns: repeat(2, 1fr);
      }
      .grid-col-1 {
        grid-template-columns: 1fr;
      }

      .category {
        margin: 0.3rem 0;
        text-align: center;
        padding: 0 1rem;
        // width: 2rem;
        flex-shrink: 0;
        .subTitle {
          padding: 0.05rem 0.2rem;
          margin-bottom: 0.3rem;
          white-space: nowrap;
          font-size: 0.18rem;
          color: #000000;
          cursor: pointer;
          &.checked,
          &:hover {
            color: #ffffff;
            background-color: #00a4ea;
          }
        }
      }
      .content {
        margin: 0.3rem 0;
        flex: 1 0;
        display: grid;
        grid-template-columns: repeat(6, 1.2rem);
        grid-template-rows: repeat(auto-fill, 1.5rem);
        row-gap: 0.3rem;
        column-gap: 0.5rem;
        .content-item {
          height: 1.5rem;
          padding: 0.05rem 0.1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 0.14rem;
          color: #000000;
          &:hover {
            background: rgba(0, 164, 234, 0.06);
            border-radius: 0.06rem 0.06rem 0.06rem 0.06rem;
            opacity: 1;
            // border: 0.01rem solid rgba(0, 164, 234, 0.5);
          }
        }
        img {
          height: 1rem;
          margin-bottom: 0.05rem;
        }
      }
    }
    .checked {
      color: #00a4ea;
      span {
        display: inline-block;
        width: calc(100% - 0.24rem);
        height: 0.03rem;
        position: absolute;
        bottom: 0.15rem;
        left: 0.12rem;
        background: #00a4ea;
      }
    }
  }
}
</style>
