<template>
  <div id="app">
    <page-header></page-header>
    <router-view class="pageview" />
    <page-footer></page-footer>
  </div>
</template>
<script>
import pageHeader from './components/pageHeader.vue'
import pageFooter from './components/pageFooter.vue'
export default {
  components: {
    pageHeader,
    pageFooter,
  },
}
</script>
<style lang="less">
#app {
  background: #f3f5f8;
  .pageview {
    margin-top: 0.75rem;
  }
  .mask::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100000px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
  }
}
</style>
