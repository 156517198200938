// 智慧医疗解决方案数据
export const smartMedicalList = [
  {
    label: '智慧医疗服务解决方案',
    children: [
      {
        label: '自助服务系统',
        imgsrc: require('@/assets/img/smartMedicalSolutions/zzfwxt.svg'),
        bannerSrc: require('@/assets/img/smartMedicalSolutions/detail_banner_zzfwxt.png'),
        description: `智慧医疗自助服务系统的建设和应用能够明显减少患者在医院就诊等待时间，为解决医院的“三长一短”问题提供有力的帮助。同时，该系统以"患者"为中心的服务理念，也是提高医院管理服务水平的重要措施。通过方便患者就诊，智慧医疗自助服务系统还减轻了医院工作人员的劳动强度，提升了医院的公益形象以及综合竞争实力。此外，智慧医疗自助服务系统还能够支持多种银医合作创新业务模式，方便患者使用银行卡或其他支付工具进行支付。同时，系统可按用户需求定制，快捷地接入第三方系统，如医院、卫生、医保、银行等接口。`,
        productSrc: require('@/assets/img/smartMedicalSolutions/prod_info_zzfwxt.svg'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/ghqh.svg'),
            title: '挂号/取号',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/jdfk.svg'),
            title: '建档/发卡',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/dbsx.svg'),
            title: '待办事项',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zzjs.svg'),
            title: '自助结算',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/bgdy.svg'),
            title: '报告打印',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/ybfw.svg'),
            title: '医保服务',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/yzsry.svg'),
            title: '一站式入院',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/cyjs.svg'),
            title: '出院结算',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/zzfwxt_charact_1.svg'),
            desc: '主动服务，通过患者身份信息获取患者待办事项，主动推送患者进行操作。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zzfwxt_charact_2.svg'),
            desc: '刷脸就医，患者可刷脸实现全流程的就医体验，方便快捷。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zzfwxt_charact_3.svg'),
            desc: '自动化完成一些常见的诊疗流程，如挂号、缴费、报告打印等，提高医院的工作效率。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zzfwxt_charact_4.svg'),
            desc: '系统支持多种支付方式，如支付宝、微信支付等，方便患者或家属进行选择。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zzfwxt_charact_5.svg'),
            desc: '采用了先进的安全技术，如加密算法、身份认证等，保障了患者使用过程中的信息安全。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zzfwxt_charact_6.svg'),
            desc: '系统可以通过大数据分析和智能算法，对医院的运营情况进行实时监控和分析。',
          },
        ],
      },
      {
        label: '可视化终端管理平台',
        imgsrc: require('@/assets/img/smartMedicalSolutions/zdglpt.svg'),
        bannerSrc: require('@/assets/img/smartMedicalSolutions/detail_banner_zdglpt.png'),
        description: `徳卡云创可视化终端管理平台可实时监控院内各软硬件系统的运行和安全情况，实现耗材剩余提醒、设备异常预警、设备远程配置、健康宣教下发、远程升级等，可保障医院自助设备的安全运行及智慧化运维管理。`,
        productSrc: require('@/assets/img/smartMedicalSolutions/prod_info_zdglpt.png'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/sjkb.svg'),
            title: '数据看板',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/aijsc.svg'),
            title: 'AI驾驶舱',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zdgl.svg'),
            title: '终端管理',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/sypz.svg'),
            title: '首页配置',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/ztpz.svg'),
            title: '主题配置',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/dmtxf.svg'),
            title: '多媒体下发',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/ycsj.svg'),
            title: '远程升级',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/hjjc.svg'),
            title: '环境监测',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/zdgl_charact_1.svg'),
            desc: '24小时设备监控，实时动态信息展示，掌握全院设备运行状态。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zdgl_charact_2.svg'),
            desc: '数据分析，包括业务数据和财务数据，图表展示，一目了然。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zdgl_charact_3.svg'),
            desc: 'AI驾驶舱，核心数据动态展示，为管理者提供可视化控制看板，科技感十足。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zdgl_charact_4.svg'),
            desc: '设备分组，根据医院需要对设备进行不同功能的可视化配置，简单便捷。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zdgl_charact_5.svg'),
            desc: '多媒体实时投放，根据需要将不同的多媒体内容远程投放至设备。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zdgl_charact_6.svg'),
            desc: '远程升级，升级包一键下发，也可分批下发，详细统计升级结果。',
          },
        ],
      },
      {
        label: '分诊排队叫号系统',
        imgsrc: require('@/assets/img/smartMedicalSolutions/pdjhxt.svg'),
        bannerSrc: require('@/assets/img/smartMedicalSolutions/detail_banner_pdjhxt.png'),
        description: `分诊排队叫号系统是一种智能化呼叫和排队管理系统，主要应用于医院门诊楼、住院楼、综合楼的各候诊、收费、取药处。该系统可以使医生和护士通过智能化手段有秩序地呼叫病员，使医院的医疗秩序规范化，门诊管理现代化。`,
        productSrc: require('@/assets/img/smartMedicalSolutions/prod_info_pdjhxt.png'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/hzqd.svg'),
            title: '患者签到',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/znrd.svg'),
            title: '智能入队',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/dltz.svg'),
            title: '队列调整',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/ssdlxs.svg'),
            title: '实时队列显示',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/ghch.svg'),
            title: '过号重呼',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/sjtj.svg'),
            title: '数据统计',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/pdjh_charact_1.svg'),
            desc: '智能化，系统可以根据患者挂号科室，自动分配医生，动态调节队伍长度。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/pdjh_charact_2.svg'),
            desc: '人性化，系统提供了便捷、舒适的就诊体验，患者可以通过大屏了解就诊进程，减少虑情绪。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/pdjh_charact_3.svg'),
            desc: '灵活性，系统可以根据医院的需求进行定制和扩展，适应不同医院的需求。',
          },
        ],
      },
      {
        label: '智慧医院小程序',
        imgsrc: require('@/assets/img/smartMedicalSolutions/zhyyxcx.svg'),
        bannerSrc: require('@/assets/img/smartMedicalSolutions/detail_banner_zhyyxcx.png'),
        description: `基于互联网和移动互联网技术的医疗服务平台，通过微信、支付宝等常用平台，为用户提供预约挂号、健康资讯、检查报告查询等功能。它不仅方便了患者，也提高了医生和医院的运营效率。`,
        productSrc: require('@/assets/img/smartMedicalSolutions/prod_info_zhyyxcx.png'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/yyjs.svg'),
            title: '医院介绍',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zndz.svg'),
            title: '智能导诊',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/yygh.svg'),
            title: '预约挂号',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/xsjf.svg'),
            title: '线上缴费',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/bgcx.svg'),
            title: '报告查询',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/jzjl.svg'),
            title: '就诊记录',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/rydj.svg'),
            title: '入院登记',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/cyjs2.svg'),
            title: '出院结算',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/pdjh_charact_1.svg'),
            desc: '便捷性，不受时间和地点的限制，用户可以通过手机随时随地使用，无需等待。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/pdjh_charact_2.svg'),
            desc: '个性化服务，可以根据用户的需求，提供个性化的界面设计和功能。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/pdjh_charact_3.svg'),
            desc: '实时性，实时更新医生排班、号源等信息，用户可以及时获取最新的医疗服务信息。',
          },
        ],
      },
      {
        label: '互联网医院',
        imgsrc: require('@/assets/img/smartMedicalSolutions/hlwyy.svg'),
        bannerSrc: require('@/assets/img/smartMedicalSolutions/detail_banner_hlwyy.png'),
        description: `互联网医院是一种新型的医疗机构，它通过互联网技术将医疗服务与信息技术相结合，以提供在线诊疗、咨询、购药等一站式服务为主要特点。患者可以通过互联网医院进行远程问诊、复诊，医生可以通过互联网医院进行在线处方、开具检验检查单等操作，同时支持药品配送到家服务。`,
        productSrc: require('@/assets/img/smartMedicalSolutions/prod_info_hlwyy.svg'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/zxwz.svg'),
            title: '在线问诊',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zxfz.svg'),
            title: '在线复诊',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zxky.svg'),
            title: '在线开药',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zxyy.svg'),
            title: '在线预约',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zxzf.svg'),
            title: '在线支付',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/dzbl.svg'),
            title: '电子病历',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zxjkgl.svg'),
            title: '在线健康管理',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/ypyj.svg'),
            title: '药品邮寄',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/hlwyy_charact_1.svg'),
            desc: '便捷性，打破了时间和空间的限制，可以在任何时间、任何地点接受在线服务。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/hlwyy_charact_2.svg'),
            desc: '高效性，通过在线诊疗、电子病历等方式，提高了医疗服务的效率和质量。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/hlwyy_charact_3.svg'),
            desc: '安全性，通过严格的管理制度和监管机制，确保了在线诊疗的安全性和可靠性。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/hlwyy_charact_4.svg'),
            desc: '整合性，将医疗资源进行整合，实现跨区域、跨学科的医疗服务，提高医疗资源利用率。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/hlwyy_charact_5.svg'),
            desc: '智能化，通过大数据、人工智能等技术手段，实现了智能化管理和服务，提高了医疗服务的智能化水平。',
          },
        ],
      },
      {
        label: '统一支付对账平台',
        imgsrc: require('@/assets/img/smartMedicalSolutions/zfdzpt.svg'),
        bannerSrc: require('@/assets/img/smartMedicalSolutions/detail_banner_zfdzpt.png'),
        description: `统一支付对账平台是一种集中管理和处理支付对账的系统，旨在向医疗机构提供全渠道、全场景的一站式金融交易服务。该平台一体化集成了多种金融支付渠道，如银联MISPOS、微信、支付宝、各银行聚合支付、云闪付、信用付、电子医保凭证、社保卡等，为医院HIS系统、微信公众号、支付宝服务窗、互联网医院、自助机、网站、生活支付等金融服务场景提供稳定、可靠、安全的支付服务。`,
        productSrc: require('@/assets/img/smartMedicalSolutions/prod_info_zfdzpt.svg'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/dzfqdjc.svg'),
            title: '多支付渠道集成',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/tyjyfw.svg'),
            title: '统一交易服务',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/dsdz.svg'),
            title: '定时对账',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/dbztx.svg'),
            title: '多边账提醒',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/tksh.svg'),
            title: '退款审核',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/bbgl.svg'),
            title: '报表管理',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zjjg.svg'),
            title: '资金监管',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/zfdzpt_charact_1.svg'),
            desc: '支付渠道集中管理，商户可视化配置，便于医院对统一支付渠道的管理。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zfdzpt_charact_2.svg'),
            desc: '提供统一标准的支付开放API接口，将微信、支付宝、刷脸支付、医保支付、银联云闪付等支付接口标准化。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zfdzpt_charact_3.svg'),
            desc: '标准统一的支付场景管理，以独立接入密钥的方式统一管理窗口，自助、线上等支付场景。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zfdzpt_charact_4.svg'),
            desc: '异常交易感知，实时扫描所有交易状态未知的订单，向支付接入方推送异常订单。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zfdzpt_charact_5.svg'),
            desc: '退款审核，可对大额交易或指定支付场景发起的退款进行人工审核。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zfdzpt_charact_6.svg'),
            desc: '数据分析，基于院内业务和支付数据进行数据统计、分析、挖掘，支撑医院数据决策。',
          },
        ],
      },
      {
        label: '全流程就医',
        imgsrc: require('@/assets/img/smartMedicalSolutions/qlcjy.svg'),
        bannerSrc: require('@/assets/img/smartMedicalSolutions/detail_banner_qlcjy.png'),
        description: `依托医保终端人脸识别能力与医疗创新服务， 深度融合“医保码使用+智能终端”，灵巧地将智慧医疗+医保的服务在医院进行前置落地，实现就医模式的优化、升级，实现全流程无感就医。`,
        productSrc: require('@/assets/img/smartMedicalSolutions/prod_info_qlcjy.svg'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/qyjd.svg'),
            title: '签约建档',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/yygh.svg'),
            title: '预约挂号',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zndz.svg'),
            title: '智能导诊',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/sljz.svg'),
            title: '刷脸就诊',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/jzqd.svg'),
            title: '就诊签到',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zjjs.svg'),
            title: '诊间结算',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/qydj.svg'),
            title: '取药登记',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/bgcx.svg'),
            title: '报告查询',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/qlcjy_charact_1.svg'),
            desc: '升级就诊方式实现无感就医',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/qlcjy_charact_2.svg'),
            desc: '优化就诊路径提高就诊效率',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/qlcjy_charact_3.svg'),
            desc: '智能就诊引导实现主动服务',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/qlcjy_charact_4.svg'),
            desc: '对接医保服务打通医保结算',
          },
        ],
      },
      {
        label: '智慧护士站',
        imgsrc: require('@/assets/img/smartMedicalSolutions/zhhsz.svg'),
        bannerSrc: require('@/assets/img/smartMedicalSolutions/detail_banner_zhhsz.png'),
        description: `     智慧护士站是一种集多种功能于一体的门诊护理工作站，旨在提供高效、便捷的门诊护理服务。与传统的门诊护士站相比，智慧护士站具有更完善的功能和更合理的布局，以满足现代医疗环境的需求。`,
        productSrc: require('@/assets/img/smartMedicalSolutions/prod_info_zhhsz.png'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/hszfzqd.svg'),
            title: '护士站分诊签到',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/jzqh.svg'),
            title: '就诊取号',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/pzdy.svg'),
            title: '凭证打印',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/ghjf.svg'),
            title: '挂号缴费',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/hzxqzs.svg'),
            title: '患者详情展示',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/hlbszs.svg'),
            title: '护理标识展示',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/zhhsz_charact_1.svg'),
            desc: '集成化设计，多种功能集中在一个工作站中，优化服务模式，减少患者在院时间。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zhhsz_charact_2.svg'),
            desc: '高效性，通过合理流程设计，提高护士的工作效率，减少不必要的时间浪费。',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zhhsz_charact_3.svg'),
            desc: '可移动性，支持护士在不同病房之间轻松移动，随时满足患者的就医需求。',
          },
        ],
      },
      {
        label: 'MIS-POS',
        imgsrc: require('@/assets/img/smartMedicalSolutions/mispos.svg'),
        bannerSrc: require('@/assets/img/smartMedicalSolutions/detail_banner_mispos.png'),
        description: `       MIS-POS系统实现了医院HIS系统与银行接口相结合，利用局域网络和专线网络快速、稳定的特点，实现银行卡、云闪付、微信、支付宝、社保卡、医保码等多种支付方式，实现多渠道支付，为患者提供更快捷、更安全、更高效就诊服务。`,
        productSrc: require('@/assets/img/smartMedicalSolutions/prod_info_mispos.svg'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/apijr.svg'),
            title: 'API接入',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/skzf.svg'),
            title: '刷卡支付',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/smzf.svg'),
            title: '扫码支付',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/yltk.svg'),
            title: '原路退款',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/jycx.svg'),
            title: '交易撤销',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/pjdy.svg'),
            title: '凭据打印',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/zjjg.svg'),
            title: '资金监管',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalSolutions/mispos_charact_1.svg'),
            desc: '资金托管银行安全可靠',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/mispos_charact_2.svg'),
            desc: '软硬件加密传输一机一密',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/mispos_charact_3.svg'),
            desc: '行业权威认证质量安全可靠',
          },
          {
            img: require('@/assets/img/smartMedicalSolutions/mispos_charact_4.svg'),
            desc: '风险管理实时监控',
          },
        ],
      },
    ],
  },
]
