import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/common.css'
import './assets/style/animate.css'
import { Carousel, CarouselItem, Tree } from 'element-ui'

import 'aos/dist/aos.css'

Vue.config.productionTip = false
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Tree)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// var images = document.getElementsByTagName('img')
// function callback(entries) {
//   for (let i of entries) {
//     if (i.isIntersecting) {
//       let img = i.target
//       let trueSrc = img.getAttribute('dataSrc')
//       console.log(img)
//       if (trueSrc) {
//         let newtrueSrc = require('@/' + trueSrc.substring(2))
//         img.setAttribute('src', newtrueSrc)
//         observer.unobserve(img)
//       }
//     }
//   }
// }
// const observer = new IntersectionObserver(callback)
// for (let i of images) {
//   observer.observe(i)
// }
