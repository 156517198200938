// 智慧医疗解决方案数据
export const smartMedicalInsuranceList = [
  {
    label: '智慧医保应用方案',
    children: [
      {
        label: '医保业务综合服务',
        imgsrc: require('@/assets/img/smartMedicalInsurance/ybywzhfw.svg'),
        bannerSrc: require('@/assets/img/smartMedicalInsurance/detail_banner_ybywzhfw.png'),
        description: `医保业务综合服务是一种创新的医疗服务模式，以全国统一的医保信息平台为依托，利用云计算、大数据、物联网、5G+、人脸识别等信息化技术，为群众提供医保业务查询办理、就近购药等服务新模式。这种服务模式实现了高频业务不出村、医保报销“最多跑一次”，有效提升了医保服务的便捷性和效率。`,
        productSrc: require('@/assets/img/smartMedicalInsurance/prod_info_ybywzhfw.png'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalInsurance/cbdj.svg'),
            title: '参保登记',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybjs.svg'),
            title: '医保结算',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybdzpzyy.svg'),
            title: '医保码应用',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybzhcx.svg'),
            title: '医保账户查询',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/grjyxxcx.svg'),
            title: '个人就医信息查询',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/grylxfcx.svg'),
            title: '个人医疗消费查询',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/mzmtb.svg'),
            title: '门诊慢特病',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybmmxg.svg'),
            title: '医保密码修改',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalInsurance/ybywzhfw_charact_1.svg'),
            desc: '以群众为中心，通过信息化手段提供便捷、高效、安全的医保服务。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybywzhfw_charact_2.svg'),
            desc: '服务模式创新，从医保窗口式服务到自助设备及移动端服务模式，更加便捷和高效。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybywzhfw_charact_3.svg'),
            desc: '多元化服务，包括医保查询办理、购药服务，异地就医备案和结算等，满足群众的不同需求。',
          },
        ],
      },
      {
        label: '医保终端运维监管平台',
        imgsrc: require('@/assets/img/smartMedicalInsurance/ybzdywjg.svg'),
        bannerSrc: require('@/assets/img/smartMedicalInsurance/detail_banner_ybzdywjg.png'),
        description: `医保终端运维监管平台是一种针对医保终端设备进行管理和监督的系统，旨在提高医保服务的效率和质量。该平台通过信息化技术手段，对医保终端设备进行实时监控、故障排查、维护管理、安全防范等方面的监管，确保医保终端设备的正常运行，提高设备的可靠性和稳定性。`,
        productSrc: require('@/assets/img/smartMedicalInsurance/prod_info_ybzdywjg.png'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalInsurance/aijsc.svg'),
            title: 'AI驾驶舱',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/sbgl.svg'),
            title: '设备管理',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/jkgl.svg'),
            title: '监控管理',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/gzpc.svg'),
            title: '故障排查',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/whgl.svg'),
            title: '维护管理',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/aqff.svg'),
            title: '安全防范',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/sjtjfx.svg'),
            title: '数据统计分析',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalInsurance/ybzdywjg_charact_1.svg'),
            desc: '集中化管理，对设备进行统一的监控和管理，方便管理人员对设备进行维护和保养。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybzdywjg_charact_2.svg'),
            desc: '实时监控，及时发现设备故障和异常情况，提高设备的可靠性和稳定性。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybzdywjg_charact_3.svg'),
            desc: '快速响应和报警机制，及时处理设备故障和异常情况，保证设备的正常运行。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybzdywjg_charact_4.svg'),
            desc: '数据分析和报告，对设备运行数据进行统计和分析，生成报告和图表，为设备维护和管理提供数据支持。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybzdywjg_charact_5.svg'),
            desc: '安全防范，以保护设备免受攻击和破坏，确保数据安全。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybzdywjg_charact_6.svg'),
            desc: '灵活扩展，根据实际需求增加新的功能和模块，满足不同用户的需求。',
          },
        ],
      },
      {
        label: '医保移动支付',
        imgsrc: require('@/assets/img/smartMedicalInsurance/ybydzf.svg'),
        bannerSrc: require('@/assets/img/smartMedicalInsurance/detail_banner_ybydzf.png'),
        description: `医保移动支付是一种便民利民措施，通过手机在线完成医保统筹基金报销、个人账户、个人自费“一键”结算。参保人无需携带社保实体卡，无需在窗口排队缴费，即可享受从挂号、就诊到医保结算的全流程线上服务。目前，医保移动支付仅支持广西区内参保人在定点医疗机构门诊（包括普通门诊、门诊特殊慢性病）就诊时产生的医疗费用。`,
        productSrc: require('@/assets/img/smartMedicalInsurance/prod_info_ybydzf.png'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalInsurance/ybdzpz.svg'),
            title: '医保码',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/xsfw.svg'),
            title: '线上服务',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/jtgj.svg'),
            title: '家庭共济',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ydjy.svg'),
            title: '异地就医',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalInsurance/ybydzf_charact_1.svg'),
            desc: '便捷性，无需携带社保实体卡，通过手机即可完成医保报销和个人账户支付等操作。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybydzf_charact_2.svg'),
            desc: '高效性，简化了缴费流程，减少了缴费时间，提高了缴费效率。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybydzf_charact_3.svg'),
            desc: '安全性，采用多种安全保障措施，如数据加密、身份认证等，确保支付过程的安全性和保密性。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybydzf_charact_4.svg'),
            desc: '跨区域性，不受地域限制，参保人可以在全国范围内使用医保移动支付进行缴费和报销。',
          },
        ],
      },
      {
        label: '村医通',
        imgsrc: require('@/assets/img/smartMedicalInsurance/cyt.svg'),
        bannerSrc: require('@/assets/img/smartMedicalInsurance/detail_banner_cyt.png'),
        description: `“村医通”是依托物联网构建起的村卫生室医保结算系统，它整合了乡、村两级医保报账系统和医疗业务系统。村医可以通过手持终端开具药品清单，并与县（市、区）医保中心实时结算，让参保群众不出村就可以享受门诊报销政策。`,
        productSrc: require('@/assets/img/smartMedicalInsurance/prod_info_cyt.png'),
        functionList: [
          {
            img: require('@/assets/img/smartMedicalInsurance/ybm.svg'),
            title: '医保码',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/mzgh.svg'),
            title: '门诊挂号',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/mzjf.svg'),
            title: '门诊缴费',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/cfkl.svg'),
            title: '处方开立',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybjs.svg'),
            title: '医保结算',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/pjdy.svg'),
            title: '票据打印',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybzhcx.svg'),
            title: '医保综合查询',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybxxdj.svg'),
            title: '医保信息登记',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalInsurance/cyt_charact_1.svg'),
            desc: '便民服务，旨在方便农村参保患者及时就近看病，享受门诊报销政策，提升基层医疗服务水平。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/cyt_charact_2.svg'),
            desc: '医保实时联网结算，打通了城乡医保联网结算的“最后一公里”，城乡参保人可以就近就医实时联网结算报销。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/cyt_charact_3.svg'),
            desc: '智能监管保障基金安全，利用生物识别认证功能，能够准确验证就诊人员的真实性，智能监管保障基金安全。',
          },
        ],
      },
      {
        label: '医保终端二次开发',
        imgsrc: require('@/assets/img/smartMedicalInsurance/ybek.svg'),
        bannerSrc: require('@/assets/img/smartMedicalInsurance/detail_banner_ybek.png'),
        description: `基于过检的医保业务综合服务终端机进行二次开发，使其在原有功能的基础上，增加新的功能或模块，以提高设备的实用性和扩展性，并且根据用户需求和体验，对医保终端设备的界面进行优化和定制，以提高操作便捷性和使用舒适度。`,
        productSrc: '',
        functionList: [
          {
            img: require('@/assets/img/smartMedicalInsurance/ybdzpz.svg'),
            title: '医保码应用',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybjs.svg'),
            title: '医保结算',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/zfjs.svg'),
            title: '自费结算',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/slrz.svg'),
            title: '刷脸认证',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/qyjd.svg'),
            title: '签约建档',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/yygh.svg'),
            title: '预约挂号',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/jzqd.svg'),
            title: '就诊签到',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybcx.svg'),
            title: '医保查询',
          },
        ],
        characterList: [
          {
            img: require('@/assets/img/smartMedicalInsurance/ybek_charact_1.svg'),
            desc: '灵活性，可根据用户的需求进行定制，满足不同用户的需求，具有较高的灵活性。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybek_charact_2.svg'),
            desc: '扩展性，可增加新的功能或模块，扩展设备的应用范围，提高设备的实用性和扩展性。',
          },
          {
            img: require('@/assets/img/smartMedicalInsurance/ybek_charact_3.svg'),
            desc: '安全性，设备属于医保局过检产品，采用多种安全措施，保障用户信息安全和和医保交易安全。',
          },
        ],
      },
    ],
  },
]
