import { terminalList as TerminalList } from './terminal'
import { smartMedicalList as SmartMedicalList } from './smartMedical'
import { smartMedicalInsuranceList as SmartMedicalInsuranceList } from './smartMedicalInsurance'
import { smartSocialSecurityList as SmartSocialSecurityList } from './smartSocialSecurity'
// 首页数据
export const tabList = [
  {
    label: '首页',
    url: '/',
    index: 0,
  },
  {
    label: '智能终端',
    url: '/terminal',
    index: 1,
    dropPanel: {
      type: 1,
      col: [3, 1, 1, 1],
      data: TerminalList,
      onClick: (vue, name) => {
        vue.$router.push('/terminal?name=' + name)
        // vue.$router.go(0);
      },
    },
  },
  {
    label: '智慧医疗服务解决方案',
    url: '/smartMedicalSolutions',
    index: 2,
    dropPanel: {
      col: [2],
      data: SmartMedicalList,
      onClick: (vue, name) => {
        vue.$router.push('/smartMedicalSolutions?name=' + name)
        // vue.$router.go(0);
      },
      image: [require('@/assets/img/smartMedicalSolutions/zhyyxcx.png'), require('@/assets/img/smartMedicalSolutions/hlwyy.png')],
    },
  },
  {
    label: '智慧医保应用方案',
    url: '/smartMedicalInsurance',
    index: 3,
    dropPanel: {
      col: [2],
      data: SmartMedicalInsuranceList,
      onClick: (vue, name) => {
        vue.$router.push('/smartMedicalInsurance?name=' + name)
        // vue.$router.go(0);
      },
      image: [require('@/assets/img/smartMedicalInsurance/cyt.png')],
    },
  },
  {
    label: '数字人社应用方案',
    url: '/smartSocialSecurity',
    index: 4,
    dropPanel: {
      col: [2],
      data: SmartSocialSecurityList,
      onClick: (vue, name) => {
        vue.$router.push('/smartSocialSecurity?name=' + name)
        // vue.$router.go(0);
      },
      image: [require('@/assets/img/smartSocialSecurity/zhrs.png')],
    },
  },
  {
    label: '关于我们',
    url: '/about',
    index: 5,
  },
]
export const bannerList = [
  {
    min_url: require('@/assets/img/home/bnaner1-home-min.png'),
    url: require('@/assets/img/home/bnaner1-home.png'),
    link: '/smartMedicalInsurance',
    id: 'bnaner1',
  },
  {
    min_url: require('@/assets/img/home/bnaner2-home-min.png'),
    url: require('@/assets/img/home/bnaner2-home.png'),
    link: '/smartSocialSecurity',
    id: 'bnaner2',
  },
  {
    min_url: require('@/assets/img/home/bnaner3-home-min.png'),
    url: require('@/assets/img/home/bnaner3-home.png'),
    link: '/smartMedicalSolutions',
    id: 'bnaner3',
  },
]
export const terminalList = [
  {
    title: '自助一体化终端 M300-L',
    tip: '无人值守，全天候服务',
    label: 'M300L',
  },
  {
    title: '壁挂式自助终端 M300-J',
    tip: '小巧、轻便，场景更广泛',
    label: 'M300J',
  },
  {
    title: '医保综合业务服务终端机 M300LC-Y3F',
    tip: '立式',
    label: 'M300LC-Y3F',
  },
  {
    layout: 'top',
    child: [
      {
        title: '医保终端 F11/F11C',
        label: 'F11/F11C',
        tip: '桌面式',
        img: require('@/assets/img/home/terminal-F11.png'),
        className: 'F11',
      },
      {
        title: '医保终端 P10',
        tip: '手持式',
        label: 'P10',
        img: require('@/assets/img/home/terminal-P10.png'),
        className: 'N1A',
      },
    ],
  },
  {
    layout: 'left',
    child: [
      {
        title: 'T10-F',
        tip: '多功能刷卡识别终端',
        label: 'T10-F',
        img: require('@/assets/img/home/terminal-T10-F.png'),
        className: 'T10-F',
      },
      {
        title: 'T10-P',
        tip: '多功能扫码终端',
        label: 'T10-P',
        img: require('@/assets/img/home/terminal-T10-P.png'),
        className: 'T10-P',
      },
    ],
  },
  {
    title: 'F11P双屏智能行业终端',
    tip: '双屏智能行业终端',
    label: 'F11P双屏机',
  },
]
export const solutionList = [
  {
    layout: 3, //一行三个
    url: '/smartMedicalSolutions',
    left: {
      img: require('@/assets/img/home/solution1.png'),
      title: '智慧医疗',
      tip: '提高医疗服务效率和质量，优化就医流程，提升患者就医体验。',
    },
    right: [
      {
        title: '自助服务系统',
        tip: '建档、挂号、缴费、报告打印……',
        img: require('@/assets/img/home/fwxt.png'),
      },
      {
        title: '可视化终端管理平台',
        tip: '全院终端统一管理，内容分发简单高效',
        img: require('@/assets/img/home/glxt.png'),
      },
      {
        title: '分诊排队叫号系统',
        tip: '签到及队列管理，有序高效就医',
        img: require('@/assets/img/home/jhxt.png'),
      },
      {
        title: '智慧医院小程序',
        tip: '线上挂号、问诊、缴费、查询报告等便捷服务',
        img: require('@/assets/img/home/xcx.png'),
      },
      {
        title: '互联网医院',
        tip: '掌上医院，线上就医全流程',
        img: require('@/assets/img/home/hlwyy.png'),
      },
      {
        title: '统一支付对账平台',
        tip: '统一支付、统一对账，高效、清晰',
        img: require('@/assets/img/home/dzpt.png'),
      },
    ],
  },
  {
    layout: 3, //一行三个
    url: '/smartMedicalInsurance',
    left: {
      img: require('@/assets/img/home/solution2.png'),
      title: '智慧医保',
      tip: '以医保卡和医保码为介质，为群众提供医保服务。',
    },
    right: [
      {
        title: '医保业务综合服务',
        tip: '医保、公共服务、医保院内服务',
        img: require('@/assets/img/home/zhfw.png'),
      },
      {
        title: '医保终端运维监管平台',
        tip: '医保终端监控、医保数据分析',
        img: require('@/assets/img/home/jgpt.png'),
      },
      {
        title: '医保移动支付',
        tip: '医保结算升级，“码”上结算',
        img: require('@/assets/img/home/ybydzf.png'),
      },
      {
        title: '村医通',
        tip: '打通医保服务最后一公里',
        img: require('@/assets/img/home/cyt.png'),
      },
      {
        title: '医保终端二次开发',
        tip: '医保终端应用场景拓展，功能更强',
        img: require('@/assets/img/home/zdeckf.png'),
      },
    ],
  },
  {
    layout: 2, //一行三个
    url: '/smartSocialSecurity',
    left: {
      img: require('@/assets/img/home/solution3.png'),
      title: '数字人社',
      tip: '提供智能化、便捷化的新型社会保障服务模式。',
    },
    right: [
      {
        title: '青海人社双屏机就近办业务',
        tip: '人社服务就近办，便捷高效更安全',
        img: require('@/assets/img/home/jjbyw.png'),
      },
      {
        title: '甘肃人社酒泉景区应用',
        tip: '社保文旅服务，凭社保卡入园，市民公共服务',
        img: require('@/assets/img/home/jqyy.png'),
      },
      {
        title: '宁夏人社职业学校签到系统',
        tip: '社保校园对接，门禁管控、院内卡应用',
        img: require('@/assets/img/home/qdxt.png'),
      },
      {
        title: '陕西铜川公共就业应用',
        tip: '一站式就业服务平台，打造政务服务新模式',
        img: require('@/assets/img/home/jyyy.png'),
      },
    ],
  },
]
export const casesList = [
  {
    img: require('@/assets/img/home/guyuan_rm.png'),
    title: '固原市人民医院',
    desc: '德卡云创运用创新的平台架构，结合各类前沿技术，以数据为核心，通过可视化配置，为医院构建了一套全流程智慧自助服务系统。同时，通过可靠的统一支付对账平台，确保了资金流转的准确性和安全性。这一综合解决方案的推出，标志着医疗行业在数字化转型方面迈出了坚实的一步，为医院的长远发展注入了强大的动力。',
  },
  {
    img: require('@/assets/img/home/bjszx.png'),
    title: '宝鸡市中心医院',
    desc: '德卡云创，作为业界的佼佼者，凭借丰富的项目经验和前瞻性的技术应用，总结出一套智能化就医流程，不仅简化了患者的就医步骤，还显著提升了医院的服务效率。通过科技与医疗的完美结合，为医院和患者带来了前所未有的便捷与高效，为医疗行业的数字化转型树立了新的标杆。',
  },
  {
    img: require('@/assets/img/home/xjrmyy.png'),
    title: '新疆维吾尔自治区人民医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/sqxzy.png'),
    title: '石泉县中医医院',
    desc: '德卡云创，作为业界的佼佼者，凭借丰富的项目经验和前瞻性的技术应用，总结出一套智能化就医流程，不仅简化了患者的就医步骤，还显著提升了医院的服务效率。通过科技与医疗的完美结合，为医院和患者带来了前所未有的便捷与高效，为医疗行业的数字化转型树立了新的标杆。',
  },
  {
    img: require('@/assets/img/home/hxzy.png'),
    title: '贵阳市花溪区中医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/glqrm.png'),
    title: '高陵区人民医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/jqshj.png'),
    title: '酒泉社会保障局',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/qhsrm.png'),
    title: '青海省人民医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/gysggwsjzzx.png'),
    title: '贵阳市公共卫生救治中心',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },

  {
    img: require('@/assets/img/home/xajdkq.png'),
    title: '西安交大口腔医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/lxzy.png'),
    title: '礼县中医医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/lnsybj.png'),
    title: '陇南市医保局',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/xlnsyh.png'),
    title: '西宁农商银行',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/sdx.png'),
    title: '绥德县医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/lxtsg.png'),
    title: '宁夏图书馆',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/wlmqsyy.png'),
    title: '乌鲁木齐市友谊医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/yasdsrm.png'),
    title: '延安市第四人民医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/zbfybjy.png'),
    title: '镇巴妇幼保健院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/gssrm.png'),
    title: '甘肃省人民医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/wlmqxrm.png'),
    title: '乌鲁木齐县人民医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/xjet.png'),
    title: '新疆儿童医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/my.png'),
    title: '蒙医医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/blkxrm.png'),
    title: '巴里坤县人民医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
  {
    img: require('@/assets/img/home/nqxzy.png'),
    title: '宁强县中医医院',
    desc: '德卡云创充分考虑到新疆维吾尔自治区人民医院的人文环境，精心为医院定制了自助服务解决方案。这一方案深度融入了本地居民的使用习惯，让医疗服务更加便捷、高效，为患者带来更优质的就诊体验，真正做到了科技与人文的完美结合。',
  },
]
// footer 数据
export const footer = {
  codeList: [
    {
      img: require('@/assets/img/footer/wxgzh.png'),
      title: '微信公众号',
    },
    {
      img: require('@/assets/img/footer/dcc.png'),
      title: '德卡云创网站',
    },
  ],
  informationList: [
    {
      icon: require('@/assets/img/footer/adress.svg'),
      label: '西安公司：西安市高新区科技二路67号清华科技园启迪中心K座19层',
    },
    {
      icon: require('@/assets/img/footer/phone.svg'),
      label: '服务热线：400-050-7816',
    },
    {
      icon: require('@/assets/img/footer/adress.svg'),
      label: '兰州公司：兰州市城关区南滨河东路名城广场2号楼16层',
    },
    {
      icon: require('@/assets/img/footer/phone.svg'),
      label: '服务热线：13893118151',
    },
    {
      icon: require('@/assets/img/footer/adress.svg'),
      label: '新疆公司：新疆乌鲁木齐市新市区北京南路370号银通大厦16层',
    },
    {
      icon: require('@/assets/img/footer/phone.svg'),
      label: '服务热线：0991-8792114',
    },
  ],
  otherinformationList: [
    {
      icon: require('@/assets/img/footer/adress.svg'),
      label: '贵阳公司：贵阳市南明区花果园大街贵阳国际中心2号楼2单元5层',
    },
    {
      icon: require('@/assets/img/footer/phone.svg'),
      label: '服务热线：0755-86675660/61',
    },
    {
      icon: require('@/assets/img/footer/weizhi.svg'),
      label: '厂址：深圳市南山区西丽镇茶光路文光工业区17栋四楼',
    },
    {
      icon: require('@/assets/img/footer/email.svg'),
      label: '邮箱：zj@dccsmart.com',
    },
    {
      icon: require('@/assets/img/footer/net.svg'),
      label: '网址：www.dccsmart.com',
    },
    {
      icon: require('@/assets/img/footer/phone.svg'),
      label: '服务监督电话：18909292135',
    },
  ],
}
