import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const routes = [
  {
    path: '*', //匹配未定义的路由
    redirect: '/', //重定向
  },
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
  },
  {
    path: '/terminal',
    name: 'terminal',
    component: () => import('../views/terminal.vue'),
  },
  // 智慧医疗解决方案
  {
    path: '/smartMedicalSolutions',
    name: 'smartMedicalSolutions',
    component: () => import('../views/smartMedicalSolutions.vue'),
  },
  // 智慧医保解决方案
  {
    path: '/smartMedicalInsurance',
    name: 'smartMedicalInsurance',
    component: () => import('../views/smartMedicalInsurance.vue'),
  },
  // 智慧人社解决方案
  {
    path: '/smartSocialSecurity',
    name: 'smartSocialSecurity',
    component: () => import('../views/smartSocialSecurity.vue'),
  },
  // 成功案例
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/case.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about.vue'),
  },
]
const scrollBehavior = function scrollBehavior(to, from, savedPosition) {
  if (to.path === from.path) {
    return savedPosition
  } else {
    return { x: 0, y: 0, behavior: 'smooth' }
  }
}
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
})

export default router
