<template>

  <div class="pageFooter">

    <div class="left">

      <div class="codeBox" v-for="v in footer.codeList">

        <img :src="v.img" />

        <p>{{ v.title }}</p>

      </div>

    </div>

    <div class="right">

      <p class="line"></p>

      <div class="information">

        <div class="information_left">

          <div class="inforItem" v-for="v in footer.informationList">

            <img :src="v.icon" />

            <p>{{ v.label }}</p>

          </div>

        </div>

        <div class="information_left">

          <div class="inforItem" v-for="v in footer.otherinformationList">

            <img :src="v.icon" />

            <p>{{ v.label }}</p>

          </div>

        </div>

      </div>

      <p class="line bottom"></p>

      <p class="copyright">
         {{ copyright }}
        <a href="https://beian.miit.gov.cn/" target="_blank">
           陕ICP备20008893号-3
        </a>

      </p>

    </div>

  </div>

</template>

<script>
import { footer } from "@/assets/data";
export default {
  data() {
    return {
      copyright: "版权所有 © 德卡云创科技（西安）有限公司 2020 | ",
    };
  },
  computed: {
    footer() {
      return footer;
    },
  },
  methods: {},
};
</script>

<style lang="less">
.pageFooter {
  background: #00a4ea;
  height: 4.5rem;
  display: flex;
  padding: 0 3rem;
  .left {
    display: flex;
    margin-right: 0.22rem;
    .codeBox {
      margin: 1.36rem 0.3rem 0 0;
      img {
        width: 1.22rem;
        height: 1.22rem;
        display: block;
      }
      p {
        width: 1.22rem;
        text-align: center;
        font-size: 0.16rem;
        color: #fff;
        margin-top: 0.09rem;
      }
    }
  }
  .right {
    flex: 1;
    .line {
      width: 100%;
      height: 0rem;
      opacity: 0.5;
      border: 0.01rem solid #ffffff;
      margin-top: 0.6rem;
    }
    .bottom {
      margin-top: 0;
    }

    .information {
      margin-top: 0.32rem;
      display: flex;
      justify-content: space-between;
      .information_left {
        .inforItem {
          display: flex;
          margin-bottom: 0.27rem;
          img {
            width: 0.14rem;
            height: 0.18rem;
          }
          p {
            font-size: 0.15rem;
            color: #fff;
            margin: 0 0 0 0.1rem;
          }
        }
      }
    }
    .copyright {
      font-size: 0.16rem;
      color: #ffffff;
      margin-top: 0.4rem;
      a {
        color: #ffffff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>

